import React, { useEffect, useState } from 'react';
import DiagnosisItem from '../DiagnosisItem/DiagnosisItem';
import Icon from '../Icon';
import classnames from 'classnames';
import './styles.css';
import NewDiagnosisModal from '../NewDIagnosisModal';
import moment from 'moment';
import { useImageViewer } from '../../contextProviders';
import lodash from 'lodash';
import { PurchaseLicenseModal, useSessionConfiguration } from '@ohif/ui';

const DiagnosisList = ({
  api,
  dictionaryApi,
  onSelected,
  UserAuthenticationService,
  modalService,
  servicesManager,
}: {
  api: any;
  dictionaryApi: any;
  modalService: any;
  UserAuthenticationService: any;
  servicesManager: any;
}) => {
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [showNewDiagnosis, setShowNewDiagnosis] = useState(false);
  const { StudyInternalUIDs } = useImageViewer();
  const [userId, setUserId] = useState('');

  const draft = lodash.some(
    diagnosisList,
    diagnosis => diagnosis.status === 'Draft'
  );

  const [{ layout: checked }] = useSessionConfiguration();

  const tokenData = UserAuthenticationService.getTokenData();
  const userRole = tokenData.roleId
    ? tokenData.roleId
    : tokenData.userData.roleId;

  const [selected, setSelected] = useState<number>(null);
  const isActive = true;
  const onSelection = (id: number) => {
    setSelected(id);
    if (onSelected) {
      onSelected(id);
    }
  };
  const getData = async () => {
    const userId = await dictionaryApi.users.getMe();
    setUserId(userId);
    setIsloading(true);

    const result = await api.diagnosis.list(StudyInternalUIDs);
    if (userRole == 6) {
      const resultFilter = result.filter(result => result.status !== 'Draft');
      setDiagnosisList(resultFilter);
    } else {
      setDiagnosisList(result);
    }
    setIsloading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  const label = 'DIAGNOSIS';
  return (
    <div>
      <div
        onClick={() => {
          setIsMinimized(!isMinimized);
        }}
        className={classnames(
          'flex items-center pr-2 pl-[3px] h-[27px] gap-2 rounded-t-md border-b border-secondary-light cursor-pointer text-[12px]',
          {
            'bg-secondary-main': isActive,
            'bg-secondary-dark': !isActive,
          }
        )}
      >
        <Icon
          name="panel-group-open-close"
          onClick={() => setIsMinimized(!isMinimized)}
          className={classnames(
            'w-5 h-5 text-white transition duration-300 cursor-pointer',
            {
              'transform rotate-90': !isMinimized,
            }
          )}
        />
        <span className="text-white ">{label.toUpperCase()}</span>
        <div className="flex-grow" />
        <span className="text-white ">{diagnosisList.length}</span>
      </div>{' '}
      <div className=" flex  flex-col justify-center align-center mt-5">
        {userRole == 6 ? (
          <button
            onClick={() =>
              modalService.show({
                layout: checked,
                content: PurchaseLicenseModal,
                customClassName: `border-2 border-white bg-primary-dark rounded-2xl w-[450px] h-[320px] m-auto`,
                contentProps: {
                  onClose: () => {
                    modalService.hide();
                  },
                  servicesManager,
                },
              })
            }
            className="ml-2 px-2  h-8  items-center	flex content-center text-base w-15 bg-primary-light rounded-lg p-4 self-center"
          >
            {'+ Create new diagnosis'}
          </button>
        ) : null}

        {userRole == 4 && null}

        {userRole == 2 ? (
          <button
            onClick={() => {
              setShowNewDiagnosis(true);
            }}
            className="ml-2 px-2  h-8  items-center	flex content-center text-base w-15 bg-primary-light rounded-lg p-4 self-center"
          >
            {draft ? 'Continue Draft' : '+ Create new diagnosis'}
          </button>
        ) : null}

        {userRole == 3 && diagnosisList[0]?.study?.status !== 'Diagnosed' ? (
          <button
            onClick={() => {
              setShowNewDiagnosis(true);
            }}
            className="ml-2 px-2  h-8  items-center	flex content-center text-base w-15 bg-primary-light rounded-lg p-4 self-center"
          >
            {draft ? 'Continue Draft' : '+ Create new diagnosis'}
          </button>
        ) : null}

        {showNewDiagnosis && (
          <NewDiagnosisModal
            api={api}
            dictionaryApi={dictionaryApi}
            show={showNewDiagnosis}
            setShow={display => setShowNewDiagnosis(display)}
            modalTitle={'New diagnosis'}
            handleDidSave={() => {
              getData();
              setShowNewDiagnosis(false);
            }}
            handleSavedDraft={() => {
              getData();
            }}
          />
        )}
      </div>
      <div className=" w-[100%] scrollbar-hide max-h-60  overflow-y-scroll pt-4  flex  flex-col align-center">
        {!isMinimized &&
          diagnosisList &&
          diagnosisList
            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            ?.map((e, i) => (
              <DiagnosisItem
                dictionaryApi={dictionaryApi}
                user={userId}
                api={api}
                diagnosisIndex={i}
                diagnosis={e}
                key={e.id}
                label={`${moment(e.createdAt).format('DD-MM-YYYY')}`}
                selected={selected}
                onSelected={onSelection}
                description={e.text}
              />
            ))}
      </div>
    </div>
  );
};

export default DiagnosisList;
