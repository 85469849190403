import { Button } from '@ohif/ui';
import React from 'react';

interface Props {
  item: any;
  handleAssing: (id, physicianId) => void;
  id: string;
}

const LastRequestingPhysiciansItem: React.FC<Props> = ({
  item,
  handleAssing,
  id,
}) => {
  return (
    <div
      key={item.physicianId}
      className="flex mx-2 justify-between border-white border items-center my-5 p-2 rounded-lg"
    >
      <div className="flex items-center">
        <div>
          <div className="flex">
            <h5 className="max-w-[150px] truncate">
              IČZ: {item?.medicalEstablishment ?? '... '}
            </h5>
            <span className="mx-2">|</span>
            <h5 className="max-w-[150px] truncate">
              IČP: {item?.medicalEstablishmentBranch ?? '... '}
            </h5>
          </div>
          <div className="flex">
            <h5 className="max-w-[150px] truncate">
              {item?.user?.lastName ?? ''}
              {(item?.user?.lastName || item?.user?.firstName) && ', '}
              {item?.user?.firstName ?? ''}
            </h5>
            <span className="mx-2">|</span>
          </div>
        </div>
        <Button
          className={'h-[30px]'}
          onClick={() => handleAssing(id, item?.id)}
        >
          Assign
        </Button>
      </div>
    </div>
  );
};

export default LastRequestingPhysiciansItem;
