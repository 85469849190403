import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  RectangleButton,
  DashBoardTitleBox,
  RecommendCarianModal,
  Icon,
} from '@ohif/ui';
import ChangeClinicModal from './ChangeClinicModal';
interface Props {
  userName: string;
  api: any;
  checked: boolean;
}

const ReadingPhysicianDashboard: React.FC<Props> = ({
  userName,
  api,
  checked,
  userAuthenticationService,
  modalService,
  servicesManager,
}) => {
  const [newStudiesCount, setNewStudiesCount] = useState<number | null>(0);
  const [draftStudiesCount, setDraftStudiesCount] = useState<number | null>(0);
  const [clinicData, setClinicData] = useState([]);
  const currentClinic = userAuthenticationService.getClinic();

  const clinicName =
    currentClinic && clinicData
      ? clinicData.find(
        clinic => clinic.id.toLowerCase() === currentClinic.toLowerCase()
      )?.name
      : '';

  useEffect(() => {
    getData();
  }, [currentClinic]);

  const getData = async () => {
    api.api.setClinic(currentClinic);
    const draftCount = await api.study.getDraftCount();
    setDraftStudiesCount(draftCount || 0);
    const newCount = await api.study.getCountNewStudies();
    setNewStudiesCount(newCount || 0);
    const clinicData = await api.users.getMe();
    setClinicData(clinicData.clinics);
  };

  return (
    <div
      className={` flex items-center ${checked ? 'justify-start' : 'justify-center'
        } align-middle content-center	self-center	justify-self-center	flex-1 mt-[6em] mx-2`}
    >
      <div className="grid justify-items-center">
        <div className="mb-[5em]">
          <DashBoardTitleBox
            title={`Welcome ${userName}`}
            subTitle={
              clinicName
                ? `You are a reading physician for ${clinicName}`
                : 'Loading...'
            }
          />
        </div>
        <div className="grid  justify-items-stretch grid-cols-3 justify-items-stretch  gap-x-[60px] gap-y-[50px]  w-[45.8em] max-w-[900px] mb-[4em]">
          <RectangleButton
            text={'Change your clinic'}
            onClick={() =>
              modalService.show({
                layout: checked,
                content: ChangeClinicModal,
                contentProps: {
                  clinics: clinicData,
                  onSelectedClinic: (clinicId: string) => {
                    userAuthenticationService.setClinic(clinicId);
                    modalService.hide();
                  },
                },
                title: 'Select the clinic',
              })
            }
          />
          <Link to={`/studies`}>
            <RectangleButton
              text={`You have ${newStudiesCount?.count} new studies`}
            />
          </Link>
          <Link to={`/drafts`}>
            <RectangleButton
              text={`You have ${draftStudiesCount?.count} draft studies`}
            />
          </Link>
          <Link to={`/diagnosisDictionary`}>
            <RectangleButton text={'Edit your diagnosis dictionary'} />
          </Link>
          <RectangleButton
            onClick={() =>
              modalService.show({
                layout: checked,
                content: RecommendCarianModal,
                customClassName: `border-2 border-white bg-primary-dark rounded-2xl w-[450px] h-[320px] m-auto`,
                contentProps: {
                  servicesManager,
                  clinicName,
                  onClose: () => {
                    modalService.hide();
                  },
                },
              })
            }
            text={'Recommend Carian to a colleague'}
          />
          {/* <RectangleButton text={'Participate improve Carian'} /> */}
        </div>
      </div>
    </div>
  );
};

export default ReadingPhysicianDashboard;
