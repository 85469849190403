import { Icon } from '@ohif/ui';
import React from 'react';
interface Props {
  onClick: () => void;
  checked: boolean;
}

const SwitchButton: React.FC<Props> = ({ onClick, checked }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="pr-2">
        <Icon name="1screen-black" className="h-[27px] w-[30px]" />
      </div>
      <div className="w-[40px] flex justify-center items-center">
        <div
          onClick={onClick}
          className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in"
        >
          <input
            type="checkbox"
            checked={checked}
            className={` ${checked ? 'translate-x-4' : ''
              } toggle-checkbox absolute block w-6 h-6 rounded-full bg-secondary-dark border-4 appearance-none cursor-pointer transition-all duration-300 ease-in-out`}
          />
          <label
            htmlFor="toggle"
            className={`toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer transition-all duration-300 ease-in-out `}
          ></label>
        </div>
      </div>
      <div className="pl-2">
        <Icon name="2screen-black" className="h-[27px] w-[30px]" />
      </div>
    </div>
  );
};

export default SwitchButton;
