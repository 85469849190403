import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { servicesManager } from '../../App';
import i18n from '@ohif/i18n';
import { useTranslation } from 'react-i18next';
import {
  UserPreferences,
  AboutModal,
  Header,
  useModal,
  Typography,
} from '@ohif/ui';
interface Props { }

const { availableLanguages, defaultLanguage, currentLanguage } = i18n;

const LicensesList: React.FC<Props> = ({ hotkeysManager }) => {
  const navigate = useNavigate();
  const [licenses, setLicenses] = useState([]);
  const [licensesEntityName, setLicensesEntityName] = useState('');
  const [licenseType, setLicenseType] = useState('');
  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const versionNumber = process.env.VERSION_NUMBER;
  const buildNumber = process.env.BUILD_NUM;
  const { show, hide } = useModal();
  const { t } = useTranslation();

  const {
    UserAuthenticationService,
    CarianApiService,
  } = servicesManager.services;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    CarianApiService.api.setToken(UserAuthenticationService.getToken());
    const data = await CarianApiService.licenses.getLicenses();
    setLicenses(data);
  };

  const filteredEntityName = licenses.filter(license => {
    if (licensesEntityName === '') {
      return license;
    } else {
      return license.entityName
        .toLowerCase()
        .includes(licensesEntityName.toLowerCase());
    }
  });

  const filteredType = filteredEntityName.filter(license => {
    if (licenseType === '') {
      return license;
    } else {
      return license.entityType
        .toLowerCase()
        .includes(licenseType.toLowerCase());
    }
  });

  return (
    <section>
      <Header
        isSticky
        menuOptions={[
          {
            title: t('Header:About'),
            icon: 'info',
            onClick: () =>
              show({
                content: AboutModal,
                title: 'Carian Viewer',
                contentProps: { versionNumber, buildNumber },
              }),
          },
          {
            title: t('Header:Preferences'),
            icon: 'settings',
            onClick: () =>
              show({
                title: t('UserPreferencesModal:User Preferences'),
                content: UserPreferences,
                contentProps: {
                  hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(
                    hotkeyDefaults
                  ),
                  hotkeyDefinitions,
                  onCancel: hide,
                  currentLanguage: currentLanguage(),
                  availableLanguages,
                  defaultLanguage,
                  onSubmit: state => {
                    i18n.changeLanguage(state.language.value);
                    hotkeysManager.setHotkeys(state.hotkeyDefinitions);
                    hide();
                  },
                  onReset: () => hotkeysManager.restoreDefaultBindings(),
                  hotkeysModule: hotkeys,
                },
              }),
          },
          {
            icon: 'power-off',
            title: t('Header:Logout'),
            onClick: () => {
              navigate(
                `/logout?redirect_uri=${encodeURIComponent(
                  window.location.protocol + window.location.host
                )}`
              );
            },
          },
          {
            title: t('Header:Terms & Conditions'),
            onClick: () => {
              navigate(`/termsAndConditions`);
            },
          },
        ]}
        isReturnEnabled={true}
        onClickReturnButton={() => navigate('/')}
      />
      <div className="w-[100%] py-[20px] px-[70px] grid justify-center bg-primary-dark">
        <div className="w-[100%] flex justify-between">
          <Typography
            variant="h4"
            className="m-0 leading-tight text-3xl text-primary-light"
          >
            Licenses List
          </Typography>
          <div className="flex ">
            <Typography
              variant="h4"
              className="m-0 leading-tight text-3xl text-white mr-2"
            >
              {licenses?.length}
            </Typography>
            <Typography
              variant="h6"
              className="m-0 leading-tight text-xl text-white self-end pb-1 text-common-light"
            >
              Licenses
            </Typography>
          </div>
        </div>
        <div className="w-[1200px] mt-[50px] flex justify-around">
          <div>
            <Typography className="flex flex-row items-center cursor-pointer focus:outline-none">
              License Id
            </Typography>
            <input
              type="text"
              className="w-[150px] border-primary-main mt-2 bg-black shadow transition duration-300 appearance-none border border-primary-main hover:border-gray-500 focus:border-gray-500 focus:outline-none rounded w-full py-2 px-3 text-sm text-white leading-tight focus:outline-none bg-black"
            />
          </div>

          <div>
            <Typography className="flex flex-row items-center cursor-pointer focus:outline-none">
              Entity Name
            </Typography>
            <input
              value={licensesEntityName}
              onChange={e => setLicensesEntityName(e.target.value)}
              type="text"
              className="w-[150px] border-primary-main mt-2 bg-black shadow transition duration-300 appearance-none border border-primary-main hover:border-gray-500 focus:border-gray-500 focus:outline-none rounded w-full py-2 px-3 text-sm text-white leading-tight focus:outline-none bg-black"
            />
          </div>

          <div>
            <Typography className="flex flex-row items-center cursor-pointer focus:outline-none">
              License Type
            </Typography>
            <input
              value={licenseType}
              onChange={e => setLicenseType(e.target.value)}
              type="text"
              className="w-[150px] border-primary-main mt-2 bg-black shadow transition duration-300 appearance-none border border-primary-main hover:border-gray-500 focus:border-gray-500 focus:outline-none rounded w-full py-2 px-3 text-sm text-white leading-tight focus:outline-none bg-black"
            />
          </div>
        </div>
      </div>
      {filteredType.map(license => {
        return (
          <div key={license.id} className="w-[100%] flex justify-center">
            <div className="px-[120px] text-start w-[1200px] border-white border py-[10px] flex justify-between bg-primary-dark">
              <div className="w-content text-start truncate">
                <abbr
                  title={license.id}
                  className="truncate w-full no-underline"
                >
                  <Typography
                    variant="body"
                    className="m-0 leading-tight text-base text-white"
                  >
                    {license.id}
                  </Typography>
                </abbr>
              </div>
              <div className="w-[280px] text-start">
                <Typography
                  variant="body"
                  className="m-0 leading-tight text-base text-start text-white"
                >
                  {license.entityName}
                </Typography>
              </div>
              <div className="w-[150px] text-start">
                <Typography
                  variant="body"
                  className="m-0 leading-tight text-base text-white"
                >
                  {license.entityType}
                </Typography>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default LicensesList;
