import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class DiagnosisDictionaryApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getEntries(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        '/diagnosis-dictionary/entry'
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async createEntry(text: string, description: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/diagnosis-dictionary/entry',
        { text, description }
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async saveOrder(entryIds: string[]): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        '/diagnosis-dictionary/entry/order',
        entryIds
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async updateEntry(id: string, entry: Record<string, string>): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        `/diagnosis-dictionary/entry/${id}`,
        entry
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async deleteEntry(entryId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.delete(
        `/diagnosis-dictionary/entry/${entryId}`
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
