import api from './api';

const carianApiService = {
  name: 'CarianApiService',
  create: ({ configuration = { baseUrl: '' } }) => {
    api.api.apisauce.setBaseURL(configuration.baseUrl);
    return api;
  },
};

export default carianApiService;
