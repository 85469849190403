import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import Typography from '../Typography';
import { Button, useImageViewer } from '@ohif/ui';
import { useDebounce } from '@hooks';
import { useNavigate } from 'react-router-dom';

interface Props {
  modalTitle: string;
  show: boolean;
  setShow: (boolean) => void;
  api: any;
  draft: any;
  dictionaryApi: any;
  handleDidSave: () => void;
  handleSavedDraft: () => void;
}

const NewDiagnosisModal: React.FC<Props> = ({
  show,
  setShow,
  modalTitle,
  api,
  dictionaryApi,
  handleDidSave,
  handleSavedDraft,
}) => {
  const [dictionaryEntries, setdDictionaryEntries] = useState([]);
  const [text, setText] = useState<string>('');
  const [draft, setDraft] = useState();
  const [showDictionary, setShowDictionary] = useState(true);
  const [searchedForDraft, setSearchedForDraft] = useState(false);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const { StudyInternalUIDs } = useImageViewer();

  const getDraft = async () => {
    const result = await api.diagnosis.getDraft(StudyInternalUIDs);
    if (result) {
      setDraft(result);
      setText(result.text);
    }
    setSearchedForDraft(true);
  };
  useEffect(() => {
    getDraft();
    getDictionaryEntries();
  }, []);
  const getDictionaryEntries = async () => {
    try {
      const dictionaryEntries = await dictionaryApi.diagnosisDictionary.getEntries(
        StudyInternalUIDs
      );
      if (dictionaryEntries) setdDictionaryEntries(dictionaryEntries);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinalize = async () => {
    const newDiagnosis = {
      text,
    };
    try {
      await api.diagnosis.finalizeDraft(StudyInternalUIDs, newDiagnosis);
      setText('');
      handleDidSave();
    } catch (err) {
      console.log(err);
    }
  };

  const saveDraft = async () => {
    setSaving(true);
    if (draft) {
      await api.diagnosis.updateDraft(draft.study.id, {
        text,
      });
      setSaving(false);
    } else {
      await api.diagnosis.createDraft(StudyInternalUIDs, {
        text,
      });
      setSaving(false);
    }
    if (handleSavedDraft) {
      handleSavedDraft();
    }
  };
  const handleDiscard = async () => {
    await api.diagnosis.discardDraft(draft?.study.id);
    setText('');
    handleDidSave();
  };

  const debouncedText = useDebounce(text, 3000);
  useEffect(() => {
    if (text && text.length > 0) {
      saveDraft();
    }
  }, [debouncedText]);
  const TextAreaUpdated = e => {
    setText(e.target.value);
  };

  if (!searchedForDraft) {
    return null;
  }

  return (
    <>
      <Draggable cancel=".just-name" enableUserSelectHack={false}>
        <div
          className={`z-50 border-2 border-white bg-primary-dark rounded-2xl ${show ? '' : 'hidden'
            } absolute right-0 m-auto bottom-0`}
        >
          <div
            className={
              'p-3 w-full flex flex-row bg-secondary-dark rounded-2xl justify-between '
            }
          >
            <Typography variant="h6" className={'text-2xl text-primary-light'}>
              {modalTitle}
            </Typography>

            <button
              onClick={() => {
                setShowDictionary(!showDictionary);
              }}
              className={' text-primary-light flex-none'}
            >
              {showDictionary ? 'Hide' : 'Show'} Dictionary
            </button>
          </div>
          <div className={` flex align-center z-50 rounded-lg p-4 `}>
            <div className="w-[450px]">
              <textarea
                style={{ minHeight: 300 }}
                className="just-name p-2 text-white w-full border-2 border-white rounded-lg bg-black self-center"
                value={text}
                onChange={TextAreaUpdated}
              />
              <div className="flex gap-x-2 py-2 justify-between">
                <div>
                  <Button
                    color="White"
                    className="text-left text-white font-semibold bg-common-light"
                    disabled={saving}
                    onClick={() => {
                      handleDiscard();
                      setShow(false);
                    }}
                  >
                    Discard
                  </Button>
                </div>
                {saving && (
                  <div>
                    <Typography className={'text-[10px] text-primary-light'}>
                      Saving...
                    </Typography>
                  </div>
                )}
                <div className="gap-x-[5px] flex">
                  <Button
                    color="White"
                    className="text-left text-white font-semibold bg-common-light"
                    onClick={() => {
                      setShow(false);
                      saveDraft();
                    }}
                    disabled={saving}
                  >
                    Close
                  </Button>
                  <Button
                    className="text-right text-white font-semibold bg-secondary-light"
                    onClick={() => {
                      setShow(false);
                      saveDraft();
                    }}
                    color="White"
                    disabled={saving}
                  >
                    Save
                  </Button>
                  <Button
                    className="text-right text-white font-semibold bg-common-active"
                    onClick={handleFinalize}
                    color="White"
                    disabled={saving}
                  >
                    Finalize
                  </Button>
                </div>
              </div>
            </div>
            <div
              className={`bg-black  border-2 border-white rounded-lg overflow-y-scroll ml-10 w-80 p-4 ${showDictionary ? '' : 'hidden'
                } `}
            >
              <Typography variant="h6" className="">
                Diagnosis Dictionary
              </Typography>

              <button
                onClick={() => {
                  saveDraft();
                  navigate('/diagnosisDictionary');
                  setShowDictionary(!showDictionary);
                }}
              >
                <Typography className="">Edit</Typography>
              </button>
              <div className="mt-10 ">
                {dictionaryEntries?.length === 0 ? (
                  <div className="p-4 flex justify-center align-center">
                    <Typography
                      variant="body"
                      className={
                        'bg-secondary-light text-secondary-dark p-5 rounded-md'
                      }
                    >
                      No diagnosis saved yet
                    </Typography>
                  </div>
                ) : (
                  dictionaryEntries.map(item => {
                    return (
                      <div
                        key={item.id}
                        className="flex justify-between w-full my-2 bg-secondary-main p-2 align-center rounded-md"
                      >
                        <Typography variant="body" className="w-full">
                          {item.text}
                        </Typography>
                        <button
                          onClick={() =>
                            setText(text.concat('\n' + item.description))
                          }
                        >
                          Add
                        </button>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default NewDiagnosisModal;
