import React, { useState } from 'react';
import { Icon } from '@ohif/ui';

interface Props {
  id: string;
  onDelete: (id: string) => void;
  setItemDelete: any;
}

const HandleDelete: React.FC<Props> = ({ onDelete, id, setItemDelete }) => {
  const [show, setShow] = useState('hidden');
  function decider(value) {
    if (value === 'hidden') {
      setShow('hidden');
    }
    if (value === 'yes') {
      console.log(id);
      onDelete(id);
    }
  }
  return (
    <>
      <button
        className="text-white w-4 h-4"
        onClick={() => {
          setItemDelete({
            id: id,
            show: true,
          });
        }}
      >
        <Icon className="text-primary-light" name="old-trash" />
      </button>
    </>
  );
};

export default HandleDelete;
