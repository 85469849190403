import { Typography } from '@ohif/ui';
import React from 'react';
import HandleDelete from '../HandleDelete/HandleDelete';
import HandleEdit from '../HandleEdit/HandleEdit';

interface Props {
  title: string;
  description: string;
  onDelete: (id: string) => void;
  onEdit: (title: string, description: string, id: string) => void;
  id: string;
  setItemState: any;
  setOpenInformation: any;
  setItemDelete: any;
}

const DictionaryItem: React.FC<Props> = ({
  onDelete,
  onEdit,
  title,
  description,
  id,
  setItemState,
  setOpenInformation,
  setItemDelete,
}) => {
  return (
    <div className="rounded-md px-2 py-4 border border-white w-full z-0 flex items-center">
      <abbr
        title={title}
        className="cursor-pointer truncate w-full no-underline"
      >
        <Typography className="font-semibold text-xl break-words pt-1 truncate w-full">
          {title}
        </Typography>
      </abbr>
      <div className="w-full justify-end flex items-center gap-x-10 cursor-pointer">
        <button
          onClick={() =>
            setOpenInformation({
              title: title,
              description: description,
              show: true,
            })
          }
          className="py-2 px-4 bg-primary-light rounded-md"
        >
          Open
        </button>
        <HandleEdit
          id={id}
          title={title}
          description={description}
          setItemState={setItemState}
        />
        <HandleDelete
          onDelete={onDelete}
          id={id}
          setItemDelete={setItemDelete}
        />
      </div>
    </div>
  );
};

export default DictionaryItem;
