import React from 'react';
import { Link } from 'react-router-dom';
import { RectangleButton, DashBoardTitleBox } from '@ohif/ui';

interface Props {
  userName: string;
}

const MainDashboard: React.FC<Props> = ({ userName }) => {
  return (
    <div className="flex items-center justify-center align-middle content-center	self-center	justify-self-center	flex-1 mt-[6em]">
      <div className="grid justify-items-center">
        <div className="mb-[5em]">
          <DashBoardTitleBox title={'St. Jorge Hospital'} subTitle={userName} />
        </div>
        <div className="grid justify-items-stretch grid-cols-3 justify-items-stretch  gap-x-[60px] gap-y-[50px] w-[45.8em] max-w-[900px] mb-[4em]">
          <Link to={`/studies`}>
            <RectangleButton text={'Review all studies '} />
          </Link>
          <Link to={`/licensesList`}>
            <RectangleButton text={'Review licenses'} />
          </Link>
          <RectangleButton text={'Request admin change'} />
          <RectangleButton
            iconName="recommend-white"
            text={'Recommend colleague'}
          />
          {/* <RectangleButton
            iconName="improve-white"
            text={'Participate improve Carian'}
          /> */}
          <Link to={`/diagnosisDictionary`}>
            <RectangleButton text={'Diagnosis Dictionary '} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
