import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Link,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';

// Register Font
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src:
        'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
      fontWeight: 300,
    },
    {
      src:
        'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 400,
    },
    {
      src:
        'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 500,
    },
    {
      src:
        'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 600,
    },
  ],
});

// interface Props { }
const styles = StyleSheet.create({
  page: {
    padding: '10px',
    backgroundColor: 'white',
    display: 'flex',
    fontFamily: 'Roboto',
  },
  header: {
    width: '100%',
    display: 'flex',
    padding: '0px 0',
    alignItems: 'center',
    flexDirection: 'row',
    border: '1px solid black',
    marginBottom: '30px',
  },
  gridContent: {
    width: '100%',
    display: 'flex',
    padding: '0 0 0px 40px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  headerboxes: {
    width: '100%',
    padding: '0 10px',
    backgroundColor: 'white',
  },
  headerboxesGrid: {
    width: '100%',
    padding: ' 10px',
    backgroundColor: 'white',
  },
  headerboxesRigth: {
    width: '100%',
    padding: '0 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
  },
  boxesFooter: {
    width: '100%',
    padding: '10px 30px 0 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
  },
  headerboxesLeft: {
    width: '100%',
    padding: '0 30px',
    backgroundColor: 'white',
  },
  datailbox: {
    width: '100%',
    marginTop: '20px',
    padding: '12px 14px',
    backgroundColor: 'white',
    border: '1px solid black',
  },
  headerboxesTitle: {
    width: '200px',
    padding: '0px',
    backgroundColor: 'white',
    border: '1px solid #03184B',
  },
  textHeader: {
    textDecoration: 'none',
    padding: '1px 0',
    fontSize: '11px',
    fontWeight: 400,
    color: 'black',
  },
  textHeaderOneTime: {
    padding: '1px 0',
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
  },
  textDetailsBold: {
    color: 'black',
    fontSize: '10px',
    fontWeight: 600,
  },
  textDetails: {
    color: 'black',
    fontSize: '10px',
    fontWeight: 200,
  },
  textDetailsSecond: {
    color: 'black',
    fontSize: '10px',
    fontWeight: 200,
    marginTop: '10px',
  },
});

interface Props {
  content: any;
}

const DocuPDF: React.FC<Props> = ({
  otaCode,
  otaDate,
  patientLastName,
  clinicName,
  clinicPhoneNumber,
}) => {
  const rows = [
    {
      id: 1,
      rowName: 'Odkaz: lab.carian.healthcare/ota',
      secondRowName: 'Datum: ' + `${moment(otaDate).format('DD/MM/YYYY')}`,
      url: 'https://lab.carian.healthcare/ota',
    },
    {
      id: 2,
      rowName: `Kód: ${otaCode}`,
      secondRowName: `Laboratoř: ${clinicName}`,
      url: '',
    },
    {
      id: 3,
      rowName: `Jméno pacienta: ${patientLastName}`,
      secondRowName: `Lab. kontakt: ${clinicPhoneNumber}`,
      url: '',
    },
    {
      id: 3,
      rowName: 'RČ: Vložíte pro ověření pacienta',
      secondRowName: 'Carian Helplinka: +420 774 850 802',
      url: '',
    },
  ];

  return (
    <Document>
      <Page orientation="landscape" size="A5" style={styles.page}>
        <View>
          <View style={styles.header}>
            <View style={styles.headerboxesLeft}>
              <Text style={styles.textHeaderOneTime}>Jednorázový přístup</Text>
            </View>
            <View style={styles.headerboxesRigth}>
              <Image
                src={'https://i.ibb.co/LS6GGNQ/logocarian.png'}
                style={{ width: '80px', height: '60px' }}
              />
            </View>
          </View>
          {rows.map(row => {
            return (
              <View key={row.id} style={styles.gridContent}>
                <View style={styles.headerboxesGrid}>
                  <Link style={styles.textHeader} src={row.url}>
                    {row.rowName}
                  </Link>
                </View>
                <View style={styles.headerboxesGrid}>
                  <Text style={styles.textHeader}>{row.secondRowName}</Text>
                </View>
              </View>
            );
          })}
          <View style={styles.datailbox}>
            <Text style={styles.textDetails}>
              <Text
                style={styles.textDetailsBold}
              >{`Jednorázový přístup `}</Text>
              umožňuje žádajícímu lékaři získat pacientovu lékařskou dokumentaci
              na jeden měsíc. Pro získání trvalého přístupu ke zdravotnímu
              záznamu pacienta je potřeba plný profilový přístup.
            </Text>
            <Text style={styles.textDetailsSecond}>
              Cloud Carian Healthcare nabízí bezpečné sdílení dat mezi všemi
              zdravotnickými pracovníky a zařízeními, což umožňuje snazší správu
              lékařských záznamů, snímků a diagnóz online.
            </Text>
            <Text style={styles.textDetailsSecond}>
              Pro více informací navštivte naše webové stránky na adrese{' '}
              <Text
                style={styles.textDetailsBold}
              >{`www.carian.healthcare`}</Text>
            </Text>
            <View style={styles.boxesFooter}>
              <Text style={styles.textDetails}>Tým Carian</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DocuPDF;
