import React, { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppConfig } from '@state';
import { hotkeys } from '@ohif/core';
import {
  Header,
  useModal,
  AboutModal,
  UserPreferences,
  SwitchButton,
  useSessionConfiguration,
  Icon,
} from '@ohif/ui';
import i18n from '@ohif/i18n';
import PhysicianDashboard from './PhysicianDashboard';
import AdminDashBoard from './AdminDashboard';
import MainDashboard from './MainDashboard';
import ReadingPhysicianDashboard from './ReadingPhysicianDashboard';
import OneTimeAccessDashboard from './OneTimeAccessDashboard';
import { useNavigate } from 'react-router';

const { availableLanguages, defaultLanguage, currentLanguage } = i18n;

function Dashboard({ hotkeysManager, servicesManager }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [appConfig] = useAppConfig();
  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const {
    UserAuthenticationService,
    CarianApiService,
    UIModalService,
  } = servicesManager.services;

  CarianApiService.api.setClinic(UserAuthenticationService.getClinic());
  CarianApiService.api.setToken(UserAuthenticationService.getToken());

  const [{ layout: checked }, api] = useSessionConfiguration();

  const userProfile = UserAuthenticationService.getUser().profile;
  const tokenData = UserAuthenticationService.getTokenData();
  const dashboardShow = tokenData.roleId
    ? tokenData.roleId
    : tokenData.userData.roleId;
  const { show, hide } = useModal();
  const versionNumber = process.env.VERSION_NUMBER;
  const buildNumber = process.env.BUILD_NUM;

  function handleToggle() {
    api.setLayoutToggle(!checked);
  }
  const menuOptions = [
    {
      title: t('Header:About'),
      icon: 'info',
      onClick: () =>
        show({
          content: AboutModal,
          title: 'Carian Viewer',
          contentProps: { versionNumber, buildNumber },
        }),
    },
    {
      title: t('Header:Preferences'),
      icon: 'settings',
      onClick: () =>
        show({
          title: t('UserPreferencesModal:User Preferences'),
          content: UserPreferences,
          contentProps: {
            hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(
              hotkeyDefaults
            ),
            hotkeyDefinitions,
            onCancel: hide,
            currentLanguage: currentLanguage(),
            availableLanguages,
            defaultLanguage,
            onSubmit: state => {
              i18n.changeLanguage(state.language.value);
              hotkeysManager.setHotkeys(state.hotkeyDefinitions);
              hide();
            },
            onReset: () => hotkeysManager.restoreDefaultBindings(),
            hotkeysModule: hotkeys,
          },
        }),
    },
    {
      icon: 'power-off',
      title: t('Header:Logout'),
      onClick: () => {
        navigate(
          `/logout?redirect_uri=${encodeURIComponent(
            window.location.protocol + window.location.host
          )}`
        );
      },
    },
    {
      title: t('Header:Terms & Conditions'),
      onClick: () => {
        navigate(`/termsAndConditions`);
      },
    },
  ];

  return (
    <div
      style={{
        position: 'absolute',
        display: 'table',
        width: '100vw',
      }}
      className={classnames('bg-black h-full overflow-hidden')}
    >
      <Header
        isSticky
        menuOptions={menuOptions}
        isReturnEnabled={false}
        WhiteLabeling={appConfig.whiteLabeling}
        swichButtonOn={
          <SwitchButton checked={checked} onClick={handleToggle} />
        }
      ></Header>
      {dashboardShow == 1 ? (
        <MainDashboard userName={userProfile?.name} />
      ) : null}
      {dashboardShow == 2 ? (
        <PhysicianDashboard
          userName={userProfile?.name}
          modalService={UIModalService}
          api={CarianApiService}
          checked={checked}
          servicesManager={servicesManager}
        />
      ) : null}
      {dashboardShow == 3 ? (
        <ReadingPhysicianDashboard
          api={CarianApiService}
          modalService={UIModalService}
          userAuthenticationService={UserAuthenticationService}
          userName={userProfile?.name}
          servicesManager={servicesManager}
          checked={checked}
        />
      ) : null}
      {dashboardShow == 4 ? (
        <AdminDashBoard
          modalService={UIModalService}
          userAuthenticationService={UserAuthenticationService}
          userName={userProfile?.name}
          api={CarianApiService}
          servicesManager={servicesManager}
          checked={checked}
        />
      ) : null}
      {dashboardShow == 6 ? (
        <OneTimeAccessDashboard
          modalService={UIModalService}
          userAuthenticationService={UserAuthenticationService}
          userName={userProfile?.name}
          api={CarianApiService}
          servicesManager={servicesManager}
          checked={checked}
        />
      ) : null}
    </div>
  );
}
Dashboard.propTypes = {};
export default Dashboard;
