import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';

const DEFAULT_STATE = {
  lastTenPhysicians: [],
};

export const LastTenPhysiciansContext = createContext(DEFAULT_STATE);

export function LastTenPhysiciansProvider({ children }) {
  useEffect(() => {
    const lastTenPhysiciansStateJSON = localStorage.getItem(
      'lastTenPhysiciansState'
    );
    if (lastTenPhysiciansStateJSON) {
      dispatch({
        type: 'RESET',
        payload: JSON.parse(lastTenPhysiciansStateJSON),
      });
    }
  }, []);

  const lastTenPhysiciansReducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_LAST_TEN_PHYSICIANS': {
        const updatedDoctors = [
          action.payload.doctor,
          ...state.lastTenPhysicians
            .filter(d => d !== action.payload.doctor)
            .slice(0, 9),
        ];
        return {
          ...state,
          ...{ lastTenPhysicians: updatedDoctors },
        };
      }
      case 'RESET': {
        const { lastTenPhysicians } = action.payload;
        return {
          ...state,
          lastTenPhysicians: lastTenPhysicians.slice(0, 10),
        };
      }

      default:
        return state;
    }
  };

  const [lastTenPhysiciansState, dispatch] = useReducer(
    lastTenPhysiciansReducer,
    DEFAULT_STATE
  );

  useEffect(() => {
    const lastTenPhysiciansStateJSON = JSON.stringify(lastTenPhysiciansState);
    localStorage.setItem('lastTenPhysiciansState', lastTenPhysiciansStateJSON);
  }, [lastTenPhysiciansState]);

  const getState = useCallback(() => lastTenPhysiciansState, [
    lastTenPhysiciansState,
  ]);

  const getPhysicians = useCallback(
    () => lastTenPhysiciansState.lastTenPhysicians,
    [lastTenPhysiciansReducer]
  );

  const updateLastTenPhysicians = useCallback(
    doctor =>
      dispatch({
        type: 'UPDATE_LAST_TEN_PHYSICIANS',
        payload: {
          doctor,
        },
      }),
    [dispatch]
  );

  const apiLastPhysicians = {
    getState,
    updateLastTenPhysicians,
    getPhysicians,
  };

  return (
    <LastTenPhysiciansContext.Provider
      value={[lastTenPhysiciansState, apiLastPhysicians]}
    >
      {children}
    </LastTenPhysiciansContext.Provider>
  );
}

export default LastTenPhysiciansProvider;

const LastTenPhysiciansConsumer = LastTenPhysiciansContext.Consumer;
export { LastTenPhysiciansConsumer };

LastTenPhysiciansProvider.propTypes = {
  children: PropTypes.any,
};

export const useLastTenPhysicians = () => useContext(LastTenPhysiciansContext);
