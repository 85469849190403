import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class OneTimeAccessApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async createCode(studyId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/studies/study/${studyId}/ota`
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getTokenOTA(code: string, documentNumber: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        `/users/ota`,
        { code, documentNumber }
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
