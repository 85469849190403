import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  RectangleButton,
  DashBoardTitleBox,
  RecommendCarianModal,
  PurchaseLicenseModal,
  RecommendCarianModalforOta,
} from '@ohif/ui';

interface Props {
  userName: string;
  api: any;
  userAuthenticationService: any;
  modalService: any;
  servicesManager: any;
  checked: boolean;
}

const OneTimeAccessDashboard: React.FC<Props> = ({
  userName,
  api,
  userAuthenticationService,
  modalService,
  servicesManager,
  checked,
}) => {
  const [studyDicomId, setStudyDicomId] = useState();
  useEffect(() => {
    getStudy();
  }, []);
  const studyId = userAuthenticationService.getTokenData().studyId;
  const getStudy = async () => {
    try {
      const study = await api.study.getStudy(
        userAuthenticationService.getTokenData().studyId
      );
      setStudyDicomId(study.dicomId);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className={`flex items-center ${checked ? 'justify-start' : 'justify-center'
        } align-middle content-center	self-center	justify-self-center	flex-1 mt-[6em] mx-2`}
    >
      <div className="grid justify-items-center">
        <div className="mb-[5em]">
          <DashBoardTitleBox title={'One time access'} subTitle={userName} />
        </div>
        <div className="grid justify-items-stretch grid-cols-3 justify-items-stretch  gap-x-[60px] gap-y-[50px] w-[45.8em] max-w-[900px] mb-[4em]">
          <Link
            to={`/viewer?StudyInstanceUIDs=${studyDicomId}&StudyInternalUIDs=${studyId}`}
          >
            <RectangleButton text={'View Patient Study'} />
          </Link>
          <RectangleButton
            onClick={() =>
              modalService.show({
                layout: checked,
                content: PurchaseLicenseModal,
                customClassName: `border-2 border-white bg-primary-dark rounded-2xl w-[450px] h-[320px] m-auto`,
                contentProps: {
                  servicesManager,
                  onClose: () => {
                    modalService.hide();
                  },
                },
              })
            }
            text={'Purchase Carian License'}
          />
          <RectangleButton
            onClick={() =>
              modalService.show({
                layout: checked,
                content: RecommendCarianModalforOta,
                customClassName: `border-2 border-white bg-primary-dark rounded-2xl w-[450px] h-[320px] m-auto`,
                contentProps: {
                  servicesManager,
                  onClose: () => {
                    modalService.hide();
                  },
                },
              })
            }
            text={'Recommend colleague'}
          />
        </div>
      </div>
    </div>
  );
};

export default OneTimeAccessDashboard;
