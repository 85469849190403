import React, { useState } from 'react';
import { Icon } from '@ohif/ui';
import parseISO from 'date-fns/parseISO';
import intlFormat from 'date-fns/intlFormat';
import { format } from 'date-fns';
interface Props {
  title: string;
  id: string;
  createdAt: string;
  studyId: string;
  madeBy: string;
  description: string;
  setExportContent: any;
  exportContentState: any;
  patientName: string;
  patientId: string;
  patientBirthDate: string;
  studyDescription: string;
  studyDate: string;
  institutionName: string;
  contact: string;
  referringPhysician: string;
  IDNo: string;
  labName: string;
}

const HandleSelect: React.FC<Props> = ({
  title,
  id,
  description,
  setExportContent,
  exportContentState,
  createdAt,
  studyId,
  madeBy,
  patientName,
  patientId,
  patientBirthDate,
  studyDescription,
  studyDate,
  institutionName,
  contact,
  referringPhysician,
  IDNo,
  labName,
}) => {
  const [selectedExport, setSelectedExport] = useState(false);
  const exportContent = (
    id,
    title,
    description,
    createdAt,
    studyId,
    madeBy,
    patientName,
    patientId,
    patientBirthDate,
    studyDescription,
    studyDate,
    institutionName,
    contact,
    referringPhysician,
    IDNo,
    labName
  ) => {
    setSelectedExport(true);
    const studyDateParse = parseISO(studyDate ? studyDate.toString() : '', {
      additionalDigits: 1,
    });
    const studyDateResult = intlFormat(studyDateParse);

    const createdAtParse = parseISO(createdAt ? createdAt.toString() : '');
    const createdAtResult = intlFormat(createdAtParse);

    const patientBirthDateParse = patientBirthDate.replace(/-/g, ', ');
    const patientBirthDateResult = format(
      new Date(patientBirthDateParse),
      'MM/dd/yyyy'
    );
    setExportContent([
      ...exportContentState,
      {
        id: id,
        createdAt: createdAtResult,
        title: title,
        description: description,
        studyId: studyId,
        madeBy: madeBy,
        patientName: patientName,
        patientId: patientId,
        patientBirthDate: patientBirthDateResult,
        studyDescription: studyDescription,
        studyDate: studyDateResult,
        institutionName: institutionName,
        contact: contact,
        referringPhysician: referringPhysician,
        IDNo: IDNo,
        labName: labName,
      },
    ]);
  };

  const notExportContent = id => {
    setSelectedExport(false);
    setExportContent(exportContentState =>
      exportContentState.filter(
        exportContentState => exportContentState.id !== id
      )
    );
  };

  return (
    <>
      {selectedExport ? (
        <div
          className="bg-secondary-dark flex items-center"
          onClick={() => notExportContent(id)}
        >
          <button className="text-white h-4 flex items-center text-xl pr-2 text-secondary-dark">
            <Icon className=" w-6 ml-2" name="export_white" />
          </button>
        </div>
      ) : (
        <div
          className="bg-primary-light flex items-center"
          onClick={() => {
            exportContent(
              id,
              title,
              description,
              createdAt,
              studyId,
              madeBy,
              patientName,
              patientId,
              patientBirthDate,
              studyDescription,
              studyDate,
              institutionName,
              contact,
              referringPhysician,
              IDNo,
              labName
            );
          }}
        >
          <button className="text-white h-4 flex items-center text-xl pr-2 text-secondary-dark">
            <Icon
              className="text-secondary-dark w-6 ml-2"
              name="export_white"
            />
          </button>
        </div>
      )}
    </>
  );
};

export default HandleSelect;
