import React, { useState } from 'react';
import { Icon, Notification } from '@ohif/ui';

interface Props {
  id: string;
  onDelete: (id: string) => void;
  show: boolean;
  setItemDelete: any;
}

const DeleteModal: React.FC<Props> = ({
  show,
  id,
  onDelete,
  setItemDelete,
}) => {
  function decider(value) {
    if (value === 'hidden') {
      setItemDelete(false);
    }
    if (value === 'yes') {
      onDelete(id);
    }
  }
  return (
    <>
      {show && (
        <div
          className={`sm:w-full md:w-96 absolute left-0 z-10 right-0 bottom-0 mx-auto mb-96`}
        >
          <Notification
            id={id}
            actions={[
              {
                text: 'No',
                type: 'cancel',
                value: 'hidden',
              },
              {
                text: 'Yes',
                type: 'secondary',
                value: 'yes',
              },
            ]}
            message="Are you sure you want to delete the diagnosis?"
            // onOutsideClick={() => { }}
            onSubmit={decider}
          />
        </div>
      )}
    </>
  );
};

export default DeleteModal;
