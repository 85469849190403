import { Button, Label, Typography } from '@ohif/ui';
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

interface Props {
  setShowModal: any;
  showModal: boolean;
  exportContent: any;
  onClose: any;
}

const OneTimeAccessSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  toName: Yup.string().required(),
  clinic: Yup.string(),
  recommender: Yup.string().required(),
});

const RecommendCarianModalforOta: React.FC<Props> = ({
  onClose,
  exportContent,
  servicesManager,
}) => {
  const {
    CarianApiService,
    UserAuthenticationService,
  } = servicesManager.services;
  // const [clinicData, setClinicData] = useState([]);

  return (
    <>
      <div className={`flex align-center flex-col`}>
        <Typography
          variant="h6"
          className={
            'p-5 w-full bg-secondary-dark rounded-lg text-3xl text-primary-light'
          }
        >
          Recommend Carian to a colleague
        </Typography>
        <Typography
          variant="h6"
          className={'px-5 pt-5 pb-2 w-full rounded-lg text-lg text-white'}
        >
          Please enter your Name and your clinic and the email of your
          colleague.
        </Typography>
        <div className={` flex align-center z-50 rounded-lg p-4 w-full`}>
          <Formik
            initialValues={{
              email: '',
              recommender: '',
              toName: '',
              clinic: '',
            }}
            validationSchema={OneTimeAccessSchema}
            onSubmit={values => {
              // user name + clinic name
              const clinicResult =
                values.clinic === ''
                  ? values.recommender
                  : `${values.recommender} (${values.clinic})`;
              CarianApiService.communications.sendRecommendationEmail({
                toAddress: values.email,
                toName: values.toName,
                recommender: clinicResult,
                // clinic: values.clinic,
              });
              onClose();
            }}
          >
            {({ errors, touched, values }) => (
              <Form className={'w-full flex-col flex'}>
                <div className="flex mb-5">
                  <div className="flex flex-col">
                    <Label>Recommender</Label>
                    <Field
                      name="recommender"
                      type="tel"
                      className="bg-primary-dark text-white border-2 mt-2 px-2 border-white w-[100] rounded-md py-0.5"
                    />
                    {errors.recommender && touched.recommender ? (
                      <div>
                        <p className="text-white">{errors.recommender}</p>
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-col ml-5">
                    <Label>To</Label>
                    <Field
                      name="toName"
                      type="tel"
                      className="bg-primary-dark text-white border-2 mt-2 px-2 border-white w-[100] rounded-md py-0.5"
                    />
                    {errors.toName && touched.toName ? (
                      <div>
                        <p className="text-white">{errors.toName}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col mb-5">
                  <Label>Clinic (optional)</Label>
                  <Field
                    name="clinic"
                    type="tel"
                    className="bg-primary-dark text-white border-2 mt-2 px-2 border-white w-[100] rounded-md py-0.5"
                  />
                  {errors.clinic && touched.clinic ? (
                    <div>
                      <p className="text-white">{errors.clinic}</p>
                    </div>
                  ) : null}
                </div>
                <Label>{`Recipient's email`}</Label>
                <Field
                  name="email"
                  type="email"
                  className="bg-primary-dark text-white border-2 mt-2 px-2 border-white w-[300] rounded-md py-0.5"
                />
                {errors.email && touched.email ? (
                  <div>
                    <p className="text-white">{errors.email}</p>
                  </div>
                ) : null}
                <div className="flex gap-x-4 py-2 mt-20 justify-between">
                  <div>
                    <Button
                      onClick={() => onClose()}
                      color="White"
                      className="text-left text-white font-semibold bg-common-light"
                    >
                      Close
                    </Button>
                  </div>
                  <div className="gap-x-[10px] flex">
                    <Button
                      disabled={errors.email || values.email === ''}
                      // onClick={() => setShowModal(false)}
                      className="text-right text-white"
                      type="submit"
                    >
                      Send email
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default RecommendCarianModalforOta;
