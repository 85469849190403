import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import Typography from '../Typography';
import { Button, useImageViewer } from '@ohif/ui';
import { useDebounce } from '@hooks';

interface Props {
  modalTitle: string;
  show: boolean;
  setShow: (boolean) => void;
  api: any;
  draft: any;
  dictionaryApi: any;
  handleDidSave: () => void;
  handleSavedDraft: () => void;
  id: string;
  onEdit: (title: string, description: string, id: string) => void;
  description: string;
  setItemState: any;
  descriptionState: any;
  setDescriptionState: any;
  user?: string;
  studyPhysicianId?: string;
  userRole?: number;
  diagnosisStatus: string;
}

const NewDiagnosisModal: React.FC<Props> = ({
  show,
  id,
  setShow,
  modalTitle,
  api,
  dictionaryApi,
  handleDidSave,
  handleSavedDraft,
  description,
  descriptionState,
  setDescriptionState,
  studyPhysicianId,
  user,
  userRole,
  diagnosisStatus,
}) => {
  const [dictionaryEntries, setdDictionaryEntries] = useState([]);
  const [text, setText] = useState('');
  const [draft, setDraft] = useState();
  const [searchedForDraft, setSearchedForDraft] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showDictionary, setShowDictionary] = useState(true);

  const { StudyInternalUIDs } = useImageViewer();
  const getDraft = async () => {
    const result = await api.diagnosis.getDraft(StudyInternalUIDs);
    if (result) {
      setDraft(result);
      setText(result.text);
    }
    setSearchedForDraft(true);
  };
  useEffect(() => {
    getDraft();
    getDictionaryEntries();
  }, []);

  const getDictionaryEntries = async () => {
    try {
      const dictionaryEntries = await dictionaryApi.diagnosisDictionary.getEntries(
        StudyInternalUIDs
      );
      if (dictionaryEntries) setdDictionaryEntries(dictionaryEntries);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFinalize = async () => {
    const newDiagnosis = {
      text,
    };
    try {
      await api.diagnosis.finalizeDraft(StudyInternalUIDs, newDiagnosis);
      setText('');
      handleDidSave();
    } catch (err) {
      console.log(err);
    }
  };

  const saveDraft = async () => {
    setSaving(true);
    if (draft) {
      console.log('updating draft');
      await api.diagnosis.updateDraft(draft.study.id, {
        text,
      });
      setSaving(false);
    } else {
      console.log('creating draft');
      await api.diagnosis.createDraft(StudyInternalUIDs, {
        text,
      });
      setSaving(false);
    }
    if (handleSavedDraft) {
      handleSavedDraft();
    }
  };

  const handleDiscard = async () => {
    await api.diagnosis.discardDraft(draft.study);
    setText('');
    handleDidSave();
  };

  const debouncedText = useDebounce(text, 5000);
  useEffect(() => {
    if (text && text.length > 0) {
      saveDraft();
    }
  }, [debouncedText]);
  const TextAreaUpdated = e => {
    setDescriptionState(e.target.value);
  };

  if (!searchedForDraft) {
    return null;
  }
  return (
    <>
      <Draggable>
        <div
          className={`z-50 border-2 border-white bg-primary-dark rounded-2xl ${show ? '' : 'hidden'
            } absolute right-0 m-auto bottom-0`}
        >
          <div
            className={
              'p-3 w-full flex flex-row bg-secondary-dark rounded-2xl justify-between '
            }
          >
            <Typography variant="h6" className={'text-2xl text-primary-light'}>
              {modalTitle}
            </Typography>
            {studyPhysicianId === user && (
              <button
                onClick={() => {
                  setShowDictionary(!showDictionary);
                }}
                className={' text-primary-light flex-none'}
              >
                {showDictionary ? 'Hide' : 'Show'} Dictionary
              </button>
            )}
          </div>

          <div className={` flex align-center z-50 rounded-lg p-4 `}>
            <div className="w-[450px]">
              <textarea
                style={{ minHeight: 300 }}
                className="ust-name p-2 text-white w-full border-2 border-white rounded-lg bg-black self-center"
                value={descriptionState}
                onChange={e => studyPhysicianId === user && TextAreaUpdated(e)}
              />
              <div className="flex gap-x-4 py-2 justify-between">
                {/* userRole == 3 && diagnosisList[0]?.status !== 'New' && */}
                {studyPhysicianId === user &&
                  userRole == 3 &&
                  diagnosisStatus !== 'Diagnosed' && (
                    <div>
                      <Button
                        color="White"
                        className="text-left text-white font-semibold bg-common-light"
                        disabled={saving}
                        onClick={() => {
                          handleDiscard();
                          setShow(false);
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                <div className="gap-x-[10px] flex">
                  <Button
                    color="White"
                    className="text-left text-white font-semibold bg-common-light"
                    onClick={() => {
                      setShow(false);
                      saveDraft();
                    }}
                    disabled={saving}
                  >
                    Close
                  </Button>
                  {studyPhysicianId === user && (
                    <Button
                      className="text-right text-white font-semibold bg-common-active"
                      onClick={handleFinalize}
                      color="White"
                      disabled={saving}
                    >
                      Finalize
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {studyPhysicianId === user && (
              <>
                <div
                  className={`bg-black  border-2 border-white rounded-lg overflow-y-scroll ml-10 w-80 p-4 ${showDictionary ? '' : 'hidden'
                    } `}
                >
                  <Typography variant="h6" className="">
                    Diagnosis Dictionary
                  </Typography>
                  <div className="mt-10 ">
                    {dictionaryEntries?.length === 0 ? (
                      <div className="p-4 flex justify-center align-center">
                        <Typography
                          variant="body"
                          className={
                            'bg-secondary-light text-secondary-dark p-5 rounded-md'
                          }
                        >
                          No diagnosis saved yet
                        </Typography>
                      </div>
                    ) : (
                      dictionaryEntries.map(item => {
                        return (
                          <div
                            key={item.id}
                            className="flex justify-between w-full my-2 bg-secondary-main p-2 align-center rounded-md"
                          >
                            <Typography variant="body" className="w-full">
                              {item.text}
                            </Typography>
                            <button
                              onClick={() =>
                                setDescriptionState(
                                  descriptionState.concat(
                                    '\n' + item.description
                                  )
                                )
                              }
                            >
                              Add
                            </button>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default NewDiagnosisModal;
