import React, { useEffect, useState } from 'react';
import { Button } from '@ohif/ui';
import './scrollbar.css';
import LastRequestingPhysiciansItem from './LastRequestingPhysiciansItem';
import PhysiciansFilter from './PhysiciansFilter';
interface Props {
  servicesManager: any;
  id: string;
  onClose: () => void;
  apiLastPhysicians: any;
  lastTenPhysicians?: [];
}

const GetRequestingPhysiciansModal: React.FC<Props> = ({
  servicesManager,
  id,
  onClose,
  apiLastPhysicians,
  lastTenPhysicians,
}) => {
  const { CarianApiService } = servicesManager.services;
  const [requestingPhysicians, setRequestingPhysicians] = useState<any>([]);
  const [countRequestingPhysicians, setCountRequestingPhysicians] = useState();
  const [lastTenPhysiciansState, setLastRequestingPhysiciansState] = useState(
    []
  );
  const [filterValues, _setFilterValues] = useState({
    ...defaultFilterValues,
  });
  const setFilterValues = val => {
    if (filterValues.pageNumber === val.pageNumber) {
      val.pageNumber = 1;
    }
    _setFilterValues(val);
  };
  const [loading, setLoading] = useState<boolean>(true);
  const getRequestingPhysicians = async () => {
    try {
      const res = await CarianApiService.users.getRequestingPhysicians(
        filterValues
      );
      const countRequestingPhysicians = await CarianApiService.users.getRequestingPhysiciansCount(
        filterValues
      );
      if (lastTenPhysicians.length > 0) {
        const resLastPhysicians = await CarianApiService.users.getLastTenRequestingPhysicians(
          lastTenPhysicians
        );
        await setLastRequestingPhysiciansState(resLastPhysicians);
      }
      await setCountRequestingPhysicians(countRequestingPhysicians);
      await setRequestingPhysicians(res);
      await setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const addToLastRequestingPhysicians = async (physicianId: string) => {
    await apiLastPhysicians.updateLastTenPhysicians(physicianId);
  };

  const assignStudy = async (id: string, physicianId: string) => {
    try {
      const res = await CarianApiService.study.assignStudy(id, {
        physicianId: physicianId,
      });
      const params = new URLSearchParams(window.location.search);

      const queryParams = {};
      for (const param of params.entries()) {
        queryParams[param[0]] = param[1];
      }

      window.location.reload();

      const newParams = new URLSearchParams(queryParams).toString();
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${newParams}`
      );
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getRequestingPhysicians();
  }, []);

  useEffect(() => {
    getRequestingPhysicians();
  }, [filterValues]);
  return (
    <div>
      <div>
        {loading ? (
          <div className="w-full flex justify-center items-center py-20">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-[#789ab7] border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          <>
            {lastTenPhysicians.length > 0 && (
              <>
                <h2>Last Physicians Assigned:</h2>
                <div className="mb-5 overflow-x-scroll scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-900">
                  <div className="flex">
                    {lastTenPhysiciansState?.map((item: any) => (
                      <LastRequestingPhysiciansItem
                        key={item.id}
                        item={item}
                        id={id}
                        handleAssing={assignStudy}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className="flex w-full items-center justify-between">
              <h2 className="text-xl font-bold">Requesting Physicians:</h2>
              <h2 className="text-xl font-bold">
                <span className="text-primary-light">
                  {countRequestingPhysicians ? countRequestingPhysicians : '0'}{' '}
                </span>
                Physicians
              </h2>
            </div>
            <PhysiciansFilter
              filterValues={filterValues}
              onChange={setFilterValues}
            />
            {requestingPhysicians?.map((item: any) => {
              return (
                <div
                  key={item.id}
                  className="w-full flex justify-between border-white border items-center my-5 p-2 rounded-lg"
                >
                  <div className="flex">
                    <h5 className="max-w-[150px] truncate">
                      IČZ: {item?.medicalEstablishment ?? '... '}
                    </h5>
                    <span className="mx-2">|</span>
                    <h5 className="max-w-[150px] truncate">
                      IČP: {item?.medicalEstablishmentBranch ?? '... '}
                    </h5>
                    <span className="mx-2">|</span>
                    <h5 className="max-w-[200px] truncate">
                      {item?.user?.lastName ?? ''}
                      {(item?.user?.lastName || item?.user?.firstName) && ', '}
                      {item?.user?.firstName ?? ''}
                    </h5>
                    <span className="mx-2">|</span>
                    <h5 className="max-w-[200px] truncate">
                      {item?.city ?? ''}
                      {(item?.city || item?.country) && ', '}
                      {item?.country ?? ''}
                    </h5>
                  </div>
                  <Button
                    onClick={() => {
                      assignStudy(id, item?.id);
                      addToLastRequestingPhysicians(item?.id);
                    }}
                  >
                    Assign
                  </Button>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className="flex w-full justify-end items-center">
        <p className="m-0 leading-tight text-base text-white opacity-60 mr-4 text-base">
          Page {filterValues.pageNumber}
        </p>
        <div role="group" className="group flex-row inline-flex">
          <button
            className="leading-none font-sans text-center justify-center items-center outline-none transition duration-300 ease-in-out focus:outline-none text-white hover:opacity-80 active:opacity-100 focus:opacity-80 undefined border border-primary-main inline-flex border outline-none border border-r-0 last:border-r first:rounded-l-md last:rounded-r-md py-2 px-2 text-base"
            type="button"
            onClick={() => {
              filterValues.pageNumber > 1 &&
                setFilterValues({
                  ...filterValues,
                  pageNumber: filterValues.pageNumber - 1,
                });
            }}
            data-cy="undefined-btn"
          >
            &lt; Previous
          </button>
          <button
            className="leading-none font-sans text-center justify-center items-center outline-none transition duration-300 ease-in-out focus:outline-none text-white hover:opacity-80 active:opacity-100 focus:opacity-80 undefined border border-primary-main inline-flex border outline-none border border-r-0 last:border-r first:rounded-l-md last:rounded-r-md py-2 px-4 text-base"
            type="button"
            data-cy="undefined-btn"
            onClick={() =>
              setFilterValues({
                ...filterValues,
                pageNumber: filterValues.pageNumber + 1,
              })
            }
          >
            Next &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetRequestingPhysiciansModal;

const defaultFilterValues = {
  city: undefined,
  country: undefined,
  icp: undefined,
  icz: undefined,
  name: undefined,
  sortBy: undefined,
  sortDirection: undefined,
  resultsPerPage: 5,
  pageNumber: 1,
};
