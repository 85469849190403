import * as Yup from 'yup';

const schema = Yup.object().shape({
  textDiagnosis: Yup.string()
    .min(2, 'Too Short')
    .max(300, 'Too Long')
    .required('The Text is required'),
  descriptionDiagnosis: Yup.string()
    .min(2, 'Too Short')
    .max(300, 'Too Long')
    .required('The Desciption is required'),
});

export default schema;
