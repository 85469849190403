import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  RectangleButton,
  DashBoardTitleBox,
  RecommendCarianModal,
} from '@ohif/ui';
import ChangeClinicModal from './ChangeClinicModal';

interface Props {
  userName: string;
  api: any;
  modalService: any;
  userAuthenticationService: any;
  servicesManager: any;
  checked: boolean;
}

const AdminDashBoard: React.FC<Props> = ({
  userName,
  api,
  userAuthenticationService,
  modalService,
  servicesManager,
  checked,
}) => {
  const [errorStudiesCount, setErrorStudiesCount] = useState<number | null>(0);
  const [clinicData, setClinicData] = useState([]);
  const currentClinic = userAuthenticationService.getClinic();

  const clinicName =
    currentClinic && clinicData
      ? clinicData.find(
        clinic => clinic.id.toLowerCase() === currentClinic.toLowerCase()
      )?.name
      : '';

  useEffect(() => {
    getData();
  }, [currentClinic]);

  const getData = async () => {
    const draftCount = await api.study.getMissingPhysicianCount();
    setErrorStudiesCount(draftCount || 0);
    const clinicData = await api.users.getMe();
    setClinicData(clinicData.clinics);
  };
  return (
    <div
      className={`flex items-center ${checked ? 'justify-start' : 'justify-center'
        } align-middle content-center	self-center	justify-self-center	flex-1 mt-[6em] mx-2`}
    >
      <div className="grid justify-items-center">
        <div className="mb-[5em]">
          <DashBoardTitleBox
            title={`Welcome ${userName}`}
            subTitle={
              clinicName
                ? `Your role is clinic representative for clinic ${clinicName}`
                : 'Loading...'
            }
          />
        </div>
        <div className="grid justify-items-stretch grid-cols-3 justify-items-stretch  gap-x-[60px] gap-y-[50px]  w-[45.8em] max-w-[900px] mb-[4em]">
          <RectangleButton
            text={'Select your clinic'}
            onClick={() =>
              modalService.show({
                layout: checked,
                content: ChangeClinicModal,
                contentProps: {
                  clinics: clinicData,
                  onSelectedClinic: (clinicId: string) => {
                    userAuthenticationService.setClinic(clinicId);
                    modalService.hide();
                  },
                },
                title: 'Select the clinic',
              })
            }
          />

          <Link to={`/licensesList`}>
            <RectangleButton text={'Review your licenses'} />
          </Link>
          <Link to={`/studies`}>
            <RectangleButton text={'All studies'} />
          </Link>
          <Link to={`/studies-missingPhysician`}>
            <RectangleButton
              text={`${errorStudiesCount?.count} studies w/o requesting Physician`}
            />
          </Link>
          <RectangleButton
            onClick={() =>
              modalService.show({
                layout: checked,
                content: RecommendCarianModal,
                customClassName: `border-2 border-white bg-primary-dark rounded-2xl w-[450px] h-[320px] m-auto`,
                contentProps: {
                  servicesManager,
                  clinicName,
                  onClose: () => {
                    modalService.hide();
                  },
                },
              })
            }
            text={'Recommend Carian to a colleague'}
          />
          {/* <RectangleButton text={'Participate improve Carian'} /> */}
        </div>
      </div>
    </div>
  );
};

export default AdminDashBoard;
