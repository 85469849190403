import React from 'react';

const ChangeClinicModal = ({
  clinics,
  onSelectedClinic,
}: {
  clinics: [{ name: string; id: string }];
  onSelectedClinic: (string) => void;
}) => {
  return (
    <div className="flex gap-10 flex-col">
      {clinics.map((clinic, index) => (
        <div
          key={`clinic-${index}`}
          className="border-white cursor-pointer border-2 h-[100px] rounded-[10px] flex justify-around flex-row p-[0.9em] items-center"
          onClick={() => onSelectedClinic(clinic.id)}
        >
          <div className="flex justify-center text-center items-center">
            <span className="rectangleButtonBox-text rectangleButtonBox-onlyText">
              {clinic.name}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChangeClinicModal;
