import { InputGroup } from '@ohif/ui';
import React, { useState } from 'react';
import filtersMeta from './filtersMeta';
import { useQuery } from '@hooks';

interface Props {
  filterValues: any;
  onChange: any;
}

const PhysiciansFilter: React.FC<Props> = ({ filterValues, onChange }) => {
  const setFilterSorting = sortingValues => {
    onChange({
      ...filterValues,
      ...sortingValues,
    });
  };

  const { sortBy, sortDirection } = filterValues;
  const filterSorting = { sortBy, sortDirection };

  return (
    <div className="pt-3 ml-[-50px] pb-3 bg-primary-dark ">
      <InputGroup
        inputMeta={filtersMeta}
        values={filterValues}
        onValuesChange={onChange}
        sorting={filterSorting}
        onSortingChange={setFilterSorting}
        isSortingEnabled={true}
      />
    </div>
  );
};

export default PhysiciansFilter;
