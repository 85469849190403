import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import moment from 'moment';
// interface Props { }

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src:
        'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
      fontWeight: 300,
    },
    {
      src:
        'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 400,
    },
    {
      src:
        'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 500,
    },
    {
      src:
        'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: '10px',
    backgroundColor: 'white',
    display: 'flex',
    fontFamily: 'Roboto',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  footer: {
    padding: '10px 30px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textFooter: {
    fontSize: '12px',
    color: '#03184B',
  },
  datailbox: {
    width: '100%',
    padding: '10px',
    height: '100px',
    backgroundColor: 'white',
    border: '1px solid #03184B',
  },
  headerboxesTitle: {
    width: '200px',
    padding: '10px',
    backgroundColor: 'white',
    border: '1px solid #03184B',
  },
  textHeader: {
    padding: '1px 0',
    fontSize: '14px',
    fontWeight: 400,
    color: '#03184B',
  },
  headerboxes: {
    width: '100%',
    padding: '10px',
    backgroundColor: 'white',
    border: '1px solid #03184B',
  },
});

const DocuPDF = ({
  patientId,
  patientName,
  patientBirthDate,
  referringPhysician,
  studyDate,
  studyDescription,
  madeBy,
  createdAt,
  labName,
  IDNo,
  contact,
  description,
}) => {
  const rows = [
    {
      id: 1,
      rowName: 'ID pacienta',
      contentInfo: patientId,
    },
    {
      id: 2,
      rowName: 'Jméno pacienta',
      contentInfo: patientName,
    },
    {
      id: 3,
      rowName: 'Datum narození',
      contentInfo: patientBirthDate,
    },
    {
      id: 5,
      rowName: 'Žádající lékař',
      contentInfo: referringPhysician,
    },
    {
      id: 8,
      rowName: 'Datum studie',
      contentInfo: `${moment(studyDate).format('DD/MM/YYYY HH:mm')}`,
    },
    {
      id: 9,
      rowName: 'Popis studie',
      contentInfo: studyDescription,
    },
  ];

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.page}>
        <View>
          <View style={styles.header}>
            <View style={styles.headerboxes}>
              {/* <Text style={styles.textHeader}>Diagnosis:
                {
                  content?.map((content) => {
                    return content.title + " - "
                  })
                }
              </Text> */}
              <Text style={styles.textHeader}>Diagnóza {madeBy}</Text>
              <Text style={styles.textHeader}>Vytvořeno </Text>
              <Text style={styles.textHeader}>
                Datum a čas {`${moment(createdAt).format('DD/MM/YYYY HH:mm')}`}
              </Text>
            </View>
            <View style={styles.headerboxes}>
              <Text style={styles.textHeader}>Laboratoř {labName}</Text>
              <Text style={styles.textHeader}>IČP {IDNo}</Text>
              <Text style={styles.textHeader}>Kontakt {contact}</Text>
            </View>
          </View>
          {rows.map(row => {
            return (
              <View key={row.id} style={styles.header}>
                <View style={styles.headerboxesTitle}>
                  <Text style={styles.textHeader}>{row.rowName}</Text>
                </View>
                <View style={styles.headerboxes}>
                  <Text style={styles.textHeader}>{row.contentInfo}</Text>
                </View>
              </View>
            );
          })}
          <View style={styles.datailbox}>
            <Text style={styles.textHeader}>Detail studie</Text>
            <View style={{ marginLeft: '10px' }}>
              <Text style={styles.textHeader}>* {description}</Text>
            </View>
          </View>
        </View>
        <View style={styles.footer}>
          <View>
            <Text style={styles.textFooter}>
              Vytvořeno www.carian.healthcare
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DocuPDF;
