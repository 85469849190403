
// THIS FILE IS AUTOGENERATED AS PART OF THE EXTENSION AND MODE PLUGIN PROCESS.
// IT SHOULD NOT BE MODIFIED MANUALLY 
import extensions0 from '@ohif/extension-cornerstone';
import extensions1 from '@ohif/extension-measurement-tracking';
import extensions2 from '@ohif/extension-cornerstone-dicom-sr';
import extensions3 from '@ohif/extension-default';
import extensions4 from '@ohif/extension-dicom-pdf';
import extensions5 from '@ohif/extension-dicom-video';
import extensions6 from '@ohif/extension-cornerstone-dicom-seg';
import extensions7 from '@carian/extension-diagnosis';
import extensions8 from '@carian/extension-diagnosis-export';
import extensions9 from '@carian/extension-patient-info';
import extensions10 from '@carian/extension-onetimeaccess';
import modes0 from '@ohif/mode-longitudinal';
const extensions = [];
const modes = [];
const modesFactory = [];
window.extensions = extensions;
window.modes = modes;

extensions.push(extensions0);
extensions.push(extensions1);
extensions.push(extensions2);
extensions.push(extensions3);
extensions.push(extensions4);
extensions.push(extensions5);
extensions.push(extensions6);
extensions.push(extensions7);
extensions.push(extensions8);
extensions.push(extensions9);
extensions.push(extensions10);
modes.push(modes0);


// Add a dynamic runtime loader
export default async () => {
 for(const modeFactory of modesFactory) {
  const newModes = await modeFactory(modes,extensions);
  newModes.forEach(newMode => modes.push(newMode));
}
}
