import React from 'react';
import { Icon, Typography } from '@ohif/ui';
import { Formik, Form, Field } from 'formik';
import DiagnosisDictionaryEntrySchema from '../DictionaryItem/diagnosisDictionaryEntrySchema';
import { Button } from '@ohif/ui';

interface Props {
  id: string;
  title: string;
  onEdit: (title: string, description: string, id: string) => void;
  show: boolean;
  description: string;
  setItemState: any;
}

// interface Props { }

const EditDictionaryModal: React.FC<Props> = ({
  id,
  title,
  description,
  onEdit,
  show,
  setItemState,
}) => {
  const initialValues = {
    textDiagnosis: title,
    descriptionDiagnosis: description,
  };
  return (
    <div
      className={`${
        show ? 'block' : 'hidden'
      } z-10 w-96 h-[500px] absolute inset-0 m-auto text-white bg-primary-dark self-center border-4 rounded-lg border-common-active`}
    >
      <header className="bg-common-active flex justify-between p-4 border-2 border-common-active">
        <abbr title={title} className="truncate w-full no-underline">
          <Typography variant="h6" className="truncate w-full">
            {title}
          </Typography>
        </abbr>
        <button
          className="text-white w-6 h-6"
          onClick={() => {
            setItemState({
              textDiagnosis: null,
              descriptionDiagnosis: null,
              show: false,
            });
          }}
        >
          <Icon className="text-black" name="close" />
        </button>
      </header>
      <Formik
        initialValues={initialValues}
        validationSchema={DiagnosisDictionaryEntrySchema}
        onSubmit={(values, { resetForm }) => {
          onEdit(values.textDiagnosis, values.descriptionDiagnosis, id);
          setItemState({
            textDiagnosis: null,
            descriptionDiagnosis: null,
            show: false,
          });
          resetForm();
        }}
      >
        {({ errors, touched, resetForm, values, initialValues }) => {
          return (
            <Form>
              <div className="p-4">
                <div className="mt-4">
                  <Typography variant="body" className="pb-2 ">
                    Edit title:
                  </Typography>
                  <div>
                    <Field
                      name="textDiagnosis"
                      className=" bg-primary-dark border-2 mt-2 px-2 border-white w-full rounded-md py-0.5"
                    />
                    {errors.textDiagnosis && touched.textDiagnosis ? (
                      <div>{errors.textDiagnosis}</div>
                    ) : (
                      <div className="h-[24px]"></div>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <Typography variant="body" className="pb-2 ">
                    Edit description:{' '}
                  </Typography>
                  <div>
                    <Field
                      name="descriptionDiagnosis"
                      as="textarea"
                      className="h-48 bg-primary-dark border-2 mt-2 px-2 border-white w-full rounded-md py-0.5"
                    />
                    {errors.descriptionDiagnosis &&
                    touched.descriptionDiagnosis ? (
                      <div>{errors.descriptionDiagnosis}</div>
                    ) : (
                      <div className="h-[24px]"></div>
                    )}
                  </div>
                </div>
                <div className="flex gap-x-4 mt-0">
                  <Button
                    color="White"
                    className="text-white p-2 bg-common-light rounded-lg"
                    onClick={() => {
                      setItemState({
                        textDiagnosis: null,
                        descriptionDiagnosis: null,
                        show: false,
                      });
                    }}
                  >
                    <Typography
                      variant="body"
                      className="font-semibold text-lg"
                    >
                      Close
                    </Typography>
                  </Button>

                  <Button
                    color="White"
                    className="text-white p-2 bg-primary-light rounded-lg"
                    type="submit"
                  >
                    <Typography
                      variant="body"
                      className="font-semibold text-lg"
                    >
                      Save
                    </Typography>
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditDictionaryModal;
