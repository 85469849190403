import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from '@ohif/ui';

// Route Components
import DataSourceWrapper from './DataSourceWrapper';
import WorkList from './WorkList';
import Local from './Local';
import Debug from './Debug';
import DiagnosisDictionary from './DiagnosisDictionary';
import NotFound from './NotFound';
import buildModeRoutes from './buildModeRoutes';
import PrivateRoute from './PrivateRoute';
import Dashboard from './Dashboard';
import LicensesList from './LicensesList';
import OneTimeAccess from './OneTimeAccess';
import DraftList from './DraftList/DraftList';
import StudiesMissingPhysician from './StudiesMissingPhysician';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
// TODO: Include "routes" debug route if dev build
const bakedInRoutes = [
  // WORK LIST
  {
    path: '/',
    children: DataSourceWrapper,
    private: true,
    props: { children: Dashboard },
  },
  {
    path: '/studies',
    children: DataSourceWrapper,
    private: true,
    props: { children: WorkList },
  },
  {
    path: '/studies-missingPhysician',
    children: DataSourceWrapper,
    private: true,
    props: { children: StudiesMissingPhysician },
  },
  {
    path: '/drafts',
    children: DataSourceWrapper,
    private: true,
    props: { children: DraftList },
  },
  {
    path: '/debug',
    children: Debug,
  },
  {
    path: '/local',
    children: Local,
  },
  {
    path: '/diagnosisDictionary',
    children: DiagnosisDictionary,
  },
  {
    path: '/licensesList',
    children: LicensesList,
  },
  {
    path: '/ota',
    children: OneTimeAccess,
  },
  {
    path: '/termsAndConditions',
    children: TermsAndConditions,
  },
  // NOT FOUND (404)
  { component: NotFound },
];

// NOT FOUND (404)
const notFoundRoute = { component: NotFound };
const WorkListRoute = {
  path: '/studies',
  children: DataSourceWrapper,
  private: true,
  props: { children: WorkList },
};

const createRoutes = ({
  modes,
  dataSources,
  extensionManager,
  servicesManager,
  commandsManager,
  hotkeysManager,
  routerBasename,
  showStudyList,
}) => {
  const routes =
    buildModeRoutes({
      modes,
      dataSources,
      extensionManager,
      servicesManager,
      commandsManager,
      hotkeysManager,
    }) || [];

  const { customizationService } = servicesManager.services;

  const customRoutes = customizationService.getGlobalCustomization(
    'customRoutes'
  );
  const allRoutes = [
    ...routes,
    ...(showStudyList ? [WorkListRoute] : []),
    ...(customRoutes?.routes || []),
    ...bakedInRoutes,
    customRoutes?.notFoundRoute || notFoundRoute,
  ];

  function RouteWithErrorBoundary({ route, ...rest }) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (
      <ErrorBoundary context={`Route ${route.path}`} fallbackRoute="/">
        <route.children
          {...rest}
          {...route.props}
          route={route}
          servicesManager={servicesManager}
          hotkeysManager={hotkeysManager}
        />
      </ErrorBoundary>
    );
  }

  const { UserAuthenticationService } = servicesManager.services;

  // Note: PrivateRoutes in react-router-dom 6.x should be defined within
  // a Route element
  return (
    <Routes>
      {allRoutes.map((route, i) => {
        return route.private === true ? (
          <Route
            key={i}
            exact
            path={route.path}
            element={
              <PrivateRoute
                handleUnauthenticated={
                  UserAuthenticationService.handleUnauthenticated
                }
              >
                <RouteWithErrorBoundary route={route} />
              </PrivateRoute>
            }
          ></Route>
        ) : (
          <Route
            key={i}
            path={route.path}
            element={<RouteWithErrorBoundary route={route} />}
          />
        );
      })}
    </Routes>
  );
};

export default createRoutes;
