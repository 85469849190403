const filtersMeta = [
  {
    name: 'city',
    displayName: 'City',
    inputType: 'Text',
    isSortable: true,
    gridCol: 4,
  },
  {
    name: 'country',
    displayName: 'Country',
    inputType: 'Text',
    isSortable: true,
    gridCol: 4,
  },
  {
    name: 'icp',
    displayName: 'IČP',
    inputType: 'Text',
    isSortable: true,
    gridCol: 5,
  },
  {
    name: 'icz',
    displayName: 'IČZ',
    inputType: 'Text',
    isSortable: true,
    gridCol: 4,
  },
  {
    name: 'name',
    displayName: 'Name',
    inputType: 'Text',
    isSortable: true,
    gridCol: 4,
  },
];

export default filtersMeta;
