import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useImageViewer } from '@ohif/ui';
import PatientInfo from '@ohif/ui/src/components/PatientInfo';
import classnames from 'classnames';
import { parse, differenceInYears } from 'date-fns';
import { useTranslation } from 'react-i18next';
const classes = {
  infoHeader: 'text-base text-primary-light',
  infoText: 'text-base text-white max-w-24 truncate',
  firstRow: 'flex flex-col',
  row: 'flex flex-col ml-4',
};
export default function PanelPatientInfo({ servicesManager }) {
  const { StudyInternalUIDs } = useImageViewer();
  const [studyInfo, setStudyInfo] = useState(null);
  const { t } = useTranslation('PatientInfo');

  const {
    CarianApiService,
    UserAuthenticationService,
  } = servicesManager.services;

  useEffect(() => {
    getData();
  }, [StudyInternalUIDs]);

  const getData = async () => {
    if (StudyInternalUIDs && StudyInternalUIDs.length > 0) {
      CarianApiService.api.setToken(UserAuthenticationService.getToken());
      CarianApiService.api.setClinic(UserAuthenticationService.getClinic());
      const study = await CarianApiService.study.getStudy(StudyInternalUIDs[0]);
      setStudyInfo(study);
    }
  };

  if (!studyInfo) {
    return <div>Loading</div>;
  }
  console.log(studyInfo);
  const {
    gender: patientGender,
    documentNumber,
    birthDate,
    user: { firstName, lastName },
  } = studyInfo.patient;
  const patientName = `${firstName} ${lastName}`;
  const date = parse(birthDate, 'yyyy-MM-dd', new Date());
  const age = differenceInYears(new Date(), date);

  return (
    <div className="flex py-2">
      <div className="flex flex-col ml-2">
        <span className="text-base font-bold text-white" title={patientName}>
          {patientName}
        </span>
        <div className="flex pb-4 mt-4 mb-4 border-b border-secondary-main">
          <div className={classnames(classes.firstRow)}>
            <span className={classnames(classes.infoHeader)}>{t('Sex')}</span>
            <span
              className={classnames(classes.infoText)}
              title={patientGender}
            >
              {patientGender}
            </span>
          </div>
          <div className={classnames(classes.row)}>
            <span className={classnames(classes.infoHeader)}>{t('Age')}</span>
            <span className={classnames(classes.infoText)} title={age}>
              {age}
            </span>
          </div>
          <div className={classnames(classes.row)}>
            <span className={classnames(classes.infoHeader)}>{t('MRN')}</span>
            <span
              className={classnames(classes.infoText)}
              title={documentNumber}
            >
              {documentNumber}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

PanelPatientInfo.propTypes = {
  servicesManager: PropTypes.shape({
    services: PropTypes.shape({}).isRequired,
  }).isRequired,
};
