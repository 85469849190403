import { Button, Label, Typography } from '@ohif/ui';
import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocuPDF from './DocuPDF';
import moment from 'moment';

interface Props {
  setShowModal: any;
  showModal: boolean;
  oneTimeData: any;
  onClose: any;
  servicesManager: any;
}

const OneTimeAccessSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

const OneTimeAccessModal: React.FC<Props> = ({
  onClose,
  servicesManager,
  oneTimeData,
}) => {
  const { CarianApiService } = servicesManager.services;

  const sendCodeMail = async () => {
    try {
      await CarianApiService.communications.sendOTAEmail(oneTimeData);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const sendOTAEmailInformation = async email => {
    try {
      await CarianApiService.communications.sendOTAEmailInformation({
        ...oneTimeData,
        toAddress: email,
        clinicPhoneNumber: oneTimeData.clinicPhoneNumber ?? undefined,
        otaDate: moment(oneTimeData.otaDate).format('DD/MM/YYYY'),
        clinicName: oneTimeData.clinicName ?? undefined,
      });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className={`flex align-center flex-col`}>
        <Typography
          variant="h6"
          className={
            'p-5 w-full bg-secondary-dark rounded-lg text-3xl text-primary-light'
          }
        >
          One Time Access
        </Typography>

        <div className={` flex align-center z-50 rounded-lg p-4 w-full`}>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={OneTimeAccessSchema}
            onSubmit={values => {
              sendOTAEmailInformation(values.email);
              onClose();
            }}
          >
            {({ errors, touched, values }) => (
              <Form className={'w-full flex-col flex'}>
                <Label>Email</Label>
                <Field
                  name="email"
                  type="email"
                  className="bg-primary-dark text-white border-2 mt-2 px-2 border-white w-[300] rounded-md py-0.5"
                />
                {errors.email && touched.email ? (
                  <div>
                    <p className="text-white">{errors.email}</p>
                  </div>
                ) : null}
                <div className="flex gap-x-4 py-2 mt-20 justify-between">
                  <div>
                    <Button
                      onClick={() => onClose()}
                      color="White"
                      className="text-left text-white font-semibold bg-common-light"
                    >
                      Close
                    </Button>
                  </div>
                  <div className="gap-x-[10px] flex">
                    <div>
                      <PDFDownloadLink
                        document={<DocuPDF {...oneTimeData} />}
                        fileName={`oneTimeAccess.pdf`}
                      >
                        <Button onClick={() => onClose(false)}>
                          Generate PDF
                        </Button>
                      </PDFDownloadLink>
                    </div>
                    <Button
                      type="submit"
                      disabled={!!errors.email || values.email === ''}
                      className="text-right text-white"
                    >
                      Send to Email
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default OneTimeAccessModal;
