import React from 'react';
import { Icon } from '@ohif/ui';

interface Props {
  id: string;
  title: string;
  description: string;
  setItemState: any;
  textButton?: string;
}

const HandleEdit: React.FC<Props> = ({
  id,
  title,
  description,
  setItemState,
  textButton,
}) => {
  return (
    <>
      <button
        className="text-white h-4 flex items-center text-xl pr-2 text-secondary-dark"
        onClick={() => {
          setItemState({
            id: id,
            title: title,
            description: description,
            show: true,
          });
        }}
      >
        {textButton ? <span className="w-5 mr-4">{textButton}</span> : null}
        <Icon className="text-white w-4 ml-2" name="pencil" />
      </button>
    </>
  );
};

export default HandleEdit;
