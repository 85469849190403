import React from 'react';
import './DashBoardTitleBox.css';
interface Props {
  title: string;
  subTitle: string;
}

const DashBoardTitleBox = ({ title, subTitle }) => {
  return (
    <div className="dashBoardTitleBox border-white border-2 pl-[1.8em] pr-[0.9em] py-[20px]">
      <span className="dashBoardTitleBox-title text-primary-light">
        {title}
      </span>
      <br />
      <span className="dashBoardTitleBox-subTitle">{subTitle}</span>
    </div>
  );
};

export default DashBoardTitleBox;
