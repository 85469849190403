import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useViewportGrid } from './ViewportGridProvider';

const DEFAULT_STATE = {
  layout: false,
};

export const SessionConfigurationContext = createContext(DEFAULT_STATE);

export function SessionConfigurationProvider({ children }) {
  const sessionConfigurationReducer = (state, action) => {
    switch (action.type) {
      case 'SET_LAYOUT': {
        return {
          ...state,
          ...{ layout: action.payload.newValue },
        };
      }
      case 'RESET': {
        return DEFAULT_STATE;
      }

      default:
        return action.payload;
    }
  };

  const [sessionConfigurationState, dispatch] = useReducer(
    sessionConfigurationReducer,
    DEFAULT_STATE
  );

  const getState = useCallback(() => sessionConfigurationState, [
    sessionConfigurationState,
  ]);

  const setLayoutToggle = useCallback(
    newValue =>
      dispatch({
        type: 'SET_LAYOUT',
        payload: {
          newValue,
        },
      }),
    [dispatch]
  );

  const getLayout = useCallback(() => sessionConfigurationState.layout, [
    sessionConfigurationReducer,
  ]);

  const reset = useCallback(
    () =>
      dispatch({
        type: 'RESET',
        payload: {},
      }),
    [dispatch]
  );
  const api = {
    getState,
    getLayout,
    reset,
    setLayoutToggle,
  };

  return (
    <SessionConfigurationContext.Provider
      value={[sessionConfigurationState, api]}
    >
      {children}
    </SessionConfigurationContext.Provider>
  );
}

export default SessionConfigurationProvider;

const SessionConfigurationConsumer = SessionConfigurationContext.Consumer;
export { SessionConfigurationConsumer };

SessionConfigurationProvider.propTypes = {
  children: PropTypes.any,
};

export const useSessionConfiguration = () =>
  useContext(SessionConfigurationContext);
