import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Header,
  Typography,
  DictionaryItem,
  EditDictionaryModal,
  DiagnosisInformation,
  DeleteModal,
  useModal,
  SwitchButton,
  useSessionConfiguration,
} from '@ohif/ui';
import { Formik, Form, Field } from 'formik';
import DiagnosisDictionaryEntrySchema from '@ohif/ui/src/components/DictionaryItem/diagnosisDictionaryEntrySchema';
import { servicesManager } from '../../App';

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import i18n from '@ohif/i18n';
import { useTranslation } from 'react-i18next';
// interface Props { }

const { availableLanguages, defaultLanguage, currentLanguage } = i18n;

const DiagnosisDictionary: React.FC = ({ hotkeysManager }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const versionNumber = process.env.VERSION_NUMBER;
  const buildNumber = process.env.BUILD_NUM;
  const { show, hide } = useModal();

  const [entries, setEntries] = useState([]);
  const [disabled, setDisabled] = useState('bg-[#eaf1fe]');
  const { CarianApiService } = servicesManager.services;
  const [itemState, setItemState] = useState({
    id: '',
    title: '',
    description: '',
    show: false,
  });

  const [itemDelete, setItemDelete] = useState({
    id: '',
    show: false,
  });

  const [openInformation, setOpenInformation] = useState({
    title: '',
    description: '',
    show: false,
  });

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    getData();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window]);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const getData = async () => {
    setEntries(await CarianApiService.diagnosisDictionary.getEntries());
  };

  const handleDelete = async (id: string) => {
    await CarianApiService.diagnosisDictionary.deleteEntry(id);
    setItemDelete({ show: false });
    getData();
  };

  const handleSave = async (text: string, description: string) => {
    await CarianApiService.diagnosisDictionary.createEntry(
      text,
      description.length > 0 ? description : null
    );
    getData();
  };

  const handleConfirmOrder = async () => {
    await CarianApiService.diagnosisDictionary.saveOrder(
      entries.map(entry => entry.id)
    );
    // getData();
  };

  const handleEdit = async (text: string, description: string, id: string) => {
    await CarianApiService.diagnosisDictionary.updateEntry(id, {
      text,
      description: description.length > 0 ? description : null,
    });
    getData();
  };

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(entries, sourceIndex, targetIndex);
    setEntries(nextState);
    setDisabled('bg-primary-light');
  }

  function rows() {
    let rowsCount = 0;
    for (let step = 0; step < entries.length; step = step + 1) {
      rowsCount++;
    }
    return rowsCount;
  }
  const [{ layout: checked }, api] = useSessionConfiguration();

  function handleToggle() {
    api.setLayoutToggle(!checked);
  }

  return (
    <>
      {itemState.show ? (
        <EditDictionaryModal
          title={itemState.title}
          id={itemState.id}
          description={itemState.description}
          onEdit={handleEdit}
          show={itemState.show}
          setItemState={setItemState}
        />
      ) : null}
      <DiagnosisInformation
        title={openInformation.title}
        description={openInformation.description}
        show={openInformation.show}
        setOpenInformation={setOpenInformation}
      />
      <DeleteModal
        show={itemDelete.show}
        onDelete={handleDelete}
        id={itemDelete.id}
        setItemDelete={setItemDelete}
      />
      <div className="min-h-screen bg-black">
        <Header
          isSticky
          swichButtonOn={
            <SwitchButton onClick={handleToggle} checked={checked} />
          }
          menuOptions={[
            {
              title: t('Header:About'),
              icon: 'info',
              onClick: () =>
                show({
                  content: AboutModal,
                  title: 'Carian Viewer',
                  contentProps: { versionNumber, buildNumber },
                }),
            },
            {
              title: t('Header:Preferences'),
              icon: 'settings',
              onClick: () =>
                show({
                  title: t('UserPreferencesModal:User Preferences'),
                  content: UserPreferences,
                  contentProps: {
                    hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(
                      hotkeyDefaults
                    ),
                    hotkeyDefinitions,
                    onCancel: hide,
                    currentLanguage: currentLanguage(),
                    availableLanguages,
                    defaultLanguage,
                    onSubmit: state => {
                      i18n.changeLanguage(state.language.value);
                      hotkeysManager.setHotkeys(state.hotkeyDefinitions);
                      hide();
                    },
                    onReset: () => hotkeysManager.restoreDefaultBindings(),
                    hotkeysModule: hotkeys,
                  },
                }),
            },
            {
              icon: 'power-off',
              title: t('Header:Logout'),
              onClick: () => {
                navigate(
                  `/logout?redirect_uri=${encodeURIComponent(
                    window.location.protocol + window.location.host
                  )}`
                );
              },
            },
            {
              title: t('Header:Terms & Conditions'),
              onClick: () => {
                navigate(`/termsAndConditions`);
              },
            },
          ]}
          isReturnEnabled={true}
          onClickReturnButton={() => navigate('/')}
        />
        <section
          className={`mt-20 gap-x-40 h-full overflow-x-hidden ${checked ? 'px-10' : 'px-20 md:px-20 lg:px-40 xl:px-40 '
            } pb-10 ${checked ? 'justify-start w-[1200px]' : 'justify-center'
            } items-start`}
        >
          <div className="text-white border-2 rounded-xl border-white overflow-hidden">
            <Typography
              variant="h4"
              className="px-10 py-5 rounded-xl text-primary-light bg-secondary-dark"
            >
              Add to dictionary
            </Typography>
            <div className="bg-primary-dark">
              <Formik
                initialValues={{
                  textDiagnosis: '',
                  descriptionDiagnosis: '',
                }}
                validationSchema={DiagnosisDictionaryEntrySchema}
                onSubmit={(values, { resetForm }) => {
                  handleSave(values.textDiagnosis, values.descriptionDiagnosis);
                  resetForm();
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="px-4 pt-4">
                      <label htmlFor="text">Diagnosis Name</label>
                      <div>
                        <Field
                          name="textDiagnosis"
                          className="bg-primary-dark border-2 mt-2 px-2 border-white w-full rounded-md py-0.5"
                        />
                        {errors.textDiagnosis && touched.textDiagnosis ? (
                          <div>{errors.textDiagnosis}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="px-4 pt-2 pb-4 ">
                      <label htmlFor="text">Description</label>
                      <div>
                        <Field
                          as="textarea"
                          name="descriptionDiagnosis"
                          className="bg-primary-dark min-h-28 border-2 mt-2 px-2 border-white w-full rounded-md py-0.5"
                        />
                        {errors.descriptionDiagnosis &&
                          touched.descriptionDiagnosis ? (
                          <div>{errors.descriptionDiagnosis}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="px-4 pb-4 pt-2">
                      <button
                        type="submit"
                        className="py-2 px-6 rounded-md bg-primary-light text-black"
                      >
                        <Typography
                          variant="body"
                          color="inherit"
                          className="font-semibold text-lg"
                        >
                          Add
                        </Typography>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <div className="text-white border-2 mt-10 rounded-xl overflow-hidden border-white ">
            <div className="flex justify-between items-center px-10 py-5 rounded-xl text-primary-light bg-secondary-dark">
              <Typography variant="h4" className="text-primary-light">
                Diagnosis Dictionary
              </Typography>
              <button
                onClick={() => {
                  if (disabled === 'bg-primary-light') {
                    handleConfirmOrder();
                    setDisabled('bg-[#eaf1fe]');
                  }
                }}
                className={`${disabled} h-10 mr-2 text-white rounded-lg px-2 transition-all duration-300`}
                color="secondary"
              >
                <Typography
                  variant="body"
                  color="inherit"
                  className="font-semibold"
                >
                  Save Order
                </Typography>
              </button>
            </div>
            <div className={`bg-primary-dark p-4 h-full`}>
              {entries?.length === 0 ? (
                <div className="p-4 flex justify-center align-center">
                  <Typography
                    variant="h4"
                    className={
                      'bg-secondary-light text-secondary-dark p-5 rounded-md'
                    }
                  >
                    No diagnosis saved yet
                  </Typography>
                </div>
              ) : (
                <GridContextProvider onChange={onChange}>
                  <GridDropZone
                    id="items"
                    boxesPerRow={1}
                    rowHeight={100}
                    style={{
                      height: `${rows() * 110}px`,
                    }}
                    className="gap-20"
                  >
                    {entries?.map(item => (
                      <GridItem key={item.order}>
                        <div className="py-10 w-full z-0">
                          <DictionaryItem
                            setItemDelete={setItemDelete}
                            id={item.id}
                            onDelete={handleDelete}
                            onEdit={handleEdit}
                            title={item.text}
                            description={item.description}
                            setItemState={setItemState}
                            setOpenInformation={setOpenInformation}
                          />
                        </div>
                      </GridItem>
                    ))}
                  </GridDropZone>
                </GridContextProvider>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default DiagnosisDictionary;
