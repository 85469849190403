import { Button, Label, Typography } from '@ohif/ui';
import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { PDFDownloadLink } from '@react-pdf/renderer';

interface Props {
  setShowModal: any;
  showModal: boolean;
  onClose: any;
  servicesManager: any;
}

const PurchaseLicensesSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  name: Yup.string().required(),
  phoneNumber: Yup.number()
    .required()
    .positive()
    .integer(),
});

const PurchaseLicenseModal: React.FC<Props> = ({
  onClose,
  servicesManager,
}) => {
  const { CarianApiService } = servicesManager.services;
  return (
    <>
      <div className={`flex align-center flex-col`}>
        <Typography
          variant="h6"
          className={
            'p-5 w-full bg-secondary-dark rounded-lg text-3xl text-primary-light'
          }
        >
          Purchase License
        </Typography>

        <Typography
          variant="h6"
          className={'px-5 pt-5 pb-2 w-full rounded-lg text-lg text-white'}
        >
          Please enter your information:
        </Typography>
        <div className={` flex align-center z-50 rounded-lg p-4 w-full`}>
          <Formik
            initialValues={{
              email: '',
              name: '',
              phoneNumber: '',
            }}
            validationSchema={PurchaseLicensesSchema}
            onSubmit={values => {
              CarianApiService.communications.purchaseSendEmail({
                email: values.email,
                name: values.name,
                phoneNumber: values.phoneNumber,
              });
              onClose();
            }}
          >
            {({ errors, touched, values }) => (
              <Form className={'w-full flex-col flex'}>
                <div className="flex mb-5">
                  <div className="flex flex-col">
                    <Label>Name</Label>
                    <Field
                      name="name"
                      type="tel"
                      className="bg-primary-dark text-white border-2 mt-2 px-2 border-white w-[100] rounded-md py-0.5"
                    />
                    {errors.name && touched.name ? (
                      <div>
                        <p className="text-white">{errors.name}</p>
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-col ml-5">
                    <Label>Phone Number</Label>
                    <Field
                      name="phoneNumber"
                      type="tel"
                      className="bg-primary-dark text-white border-2 mt-2 px-2 border-white w-[100] rounded-md py-0.5"
                    />
                    {errors.phoneNumber && touched.phoneNumber ? (
                      <div>
                        <p className="text-white">{errors.phoneNumber}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
                <Label>Email</Label>
                <Field
                  name="email"
                  type="email"
                  className="bg-primary-dark text-white border-2 mt-2 px-2 border-white w-[300] rounded-md py-0.5"
                />
                {errors.email && touched.email ? (
                  <div>
                    <p className="text-white">{errors.email}</p>
                  </div>
                ) : null}
                <div className="flex gap-x-4 py-2 mt-20 justify-between">
                  <div>
                    <Button
                      onClick={() => onClose()}
                      color="White"
                      className="text-left text-white font-semibold bg-common-light"
                    >
                      Close
                    </Button>
                  </div>
                  <div className="gap-x-[10px] flex">
                    <Button
                      disabled={errors.email || values.email === ''}
                      // onClick={() => setShowModal(false)}
                      type="submit"
                      className="text-right text-white"
                    >
                      Send email
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default PurchaseLicenseModal;
