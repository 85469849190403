import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const DEFAULT_STATE = {
  user: null,
  enabled: false,
  clinic: null,
  token: null,
};

const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const UserAuthenticationContext = createContext(DEFAULT_STATE);

export function UserAuthenticationProvider({ children, service }) {
  const userAuthenticationReducer = (state, action) => {
    switch (action.type) {
      case 'SET_USER': {
        return {
          ...state,
          ...{ user: action.payload.user },
        };
      }
      case 'SET_TOKEN': {
        return {
          ...state,
          ...{ token: action.payload.token },
        };
      }
      case 'SET_CLINIC': {
        return {
          ...state,
          ...{ clinic: action.payload.clinic },
        };
      }
      case 'RESET': {
        return {
          user: null,
        };
      }

      case 'SET': {
        return {
          ...state,
          ...action.payload,
        };
      }

      default:
        return action.payload;
    }
  };

  const [userAuthenticationState, dispatch] = useReducer(
    userAuthenticationReducer,
    DEFAULT_STATE
  );

  const getState = useCallback(() => userAuthenticationState, [
    userAuthenticationState,
  ]);

  const setUser = useCallback(
    user =>
      dispatch({
        type: 'SET_USER',
        payload: {
          user,
        },
      }),
    [dispatch]
  );

  const setToken = useCallback(
    token => {
      const parsedJwt = parseJwt(token);
      if (parsedJwt.userData && parsedJwt.userData.clinics) {
        const storedClinic = localStorage.getItem('carian_currentClinic');
        if (
          storedClinic &&
          _.some(
            parsedJwt.userData.clinics,
            clinicId => clinicId.toLowerCase() === storedClinic.toLowerCase()
          )
        ) {
          setClinic(storedClinic);
        } else {
          setClinic(parsedJwt.userData.clinics[0]);
        }
      }
      return dispatch({
        type: 'SET_TOKEN',
        payload: {
          token,
        },
      });
    },
    [dispatch]
  );

  const setClinic = useCallback(
    clinic => {
      localStorage.setItem('carian_currentClinic', clinic);
      return dispatch({
        type: 'SET_CLINIC',
        payload: {
          clinic,
        },
      });
    },
    [dispatch]
  );

  const getUser = useCallback(() => userAuthenticationState.user, [
    userAuthenticationState,
  ]);

  const getClinic = useCallback(() => userAuthenticationState.clinic, [
    userAuthenticationState,
  ]);

  const reset = useCallback(
    () =>
      dispatch({
        type: 'RESET',
        payload: {},
      }),
    [dispatch]
  );

  const set = useCallback(
    payload =>
      dispatch({
        type: 'SET',
        payload,
      }),
    [dispatch]
  );

  /**
   * Sets the implementation of the UserAuthenticationService that can be used by extensions.
   *
   * @returns void
   */
  // TODO: should this be a useEffect or not?
  useEffect(() => {
    if (service) {
      service.setServiceImplementation({
        getState,
        setUser,
        setToken,
        setClinic,
        getClinic,
        getUser,
        reset,
        set,
      });
    }
  }, [
    getState,
    service,
    setUser,
    setToken,
    getUser,
    reset,
    set,
    setClinic,
    getClinic,
  ]);

  // TODO: This may not be correct, but I think we need to set the implementation for the service
  // immediately when this runs, since otherwise the authentication redirects will fail.
  // (useEffect only runs after the child components - in this case, routing logic - has failed)
  if (service) {
    service.setServiceImplementation({
      getState,
      setUser,
      setToken,
      setClinic,
      getClinic,
      getUser,
      reset,
      set,
    });
  }

  const api = {
    getState,
    setUser,
    setToken,
    setClinic,
    getClinic,
    getUser,
    getAuthorizationHeader: service.getAuthorizationHeader,
    handleUnauthenticated: service.handleUnauthenticated,
    reset,
    set,
  };

  return (
    <UserAuthenticationContext.Provider value={[userAuthenticationState, api]}>
      {children}
    </UserAuthenticationContext.Provider>
  );
}

export default UserAuthenticationProvider;

const UserAuthenticationConsumer = UserAuthenticationContext.Consumer;
export { UserAuthenticationConsumer };

UserAuthenticationProvider.propTypes = {
  children: PropTypes.any,
  service: PropTypes.shape({
    setServiceImplementation: PropTypes.func,
  }).isRequired,
};

export const useUserAuthentication = () =>
  useContext(UserAuthenticationContext);
