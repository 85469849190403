import { hotkeys } from '@ohif/core';
import i18n from '@ohif/i18n';
import {
  AboutModal,
  Header,
  SwitchButton,
  Typography,
  UserPreferences,
  useModal,
  useSessionConfiguration,
} from '@ohif/ui';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

// interface Props { }

const { availableLanguages, defaultLanguage, currentLanguage } = i18n;

const TermsAndConditions: React.FC = ({ hotkeysManager, servicesManager }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hotkeyDefinitions, hotkeyDefaults } = hotkeysManager;
  const versionNumber = process.env.VERSION_NUMBER;
  const buildNumber = process.env.BUILD_NUM;
  const { show, hide } = useModal();

  const [{ layout: checked }, api] = useSessionConfiguration();

  function handleToggle() {
    api.setLayoutToggle(!checked);
  }

  return (
    <div>
      <Header
        isSticky
        swichButtonOn={
          <SwitchButton onClick={handleToggle} checked={checked} />
        }
        menuOptions={[
          {
            title: t('Header:About'),
            icon: 'info',
            onClick: () =>
              show({
                content: AboutModal,
                title: 'Carian Viewer',
                contentProps: { versionNumber, buildNumber },
              }),
          },
          {
            title: t('Header:Preferences'),
            icon: 'settings',
            onClick: () =>
              show({
                title: t('UserPreferencesModal:User Preferences'),
                content: UserPreferences,
                contentProps: {
                  hotkeyDefaults: hotkeysManager.getValidHotkeyDefinitions(
                    hotkeyDefaults
                  ),
                  hotkeyDefinitions,
                  onCancel: hide,
                  currentLanguage: currentLanguage(),
                  availableLanguages,
                  defaultLanguage,
                  onSubmit: state => {
                    i18n.changeLanguage(state.language.value);
                    hotkeysManager.setHotkeys(state.hotkeyDefinitions);
                    hide();
                  },
                  onReset: () => hotkeysManager.restoreDefaultBindings(),
                  hotkeysModule: hotkeys,
                },
              }),
          },
          {
            icon: 'power-off',
            title: t('Header:Logout'),
            onClick: () => {
              navigate(
                `/logout?redirect_uri=${encodeURIComponent(
                  window.location.protocol + window.location.host
                )}`
              );
            },
          },
        ]}
        isReturnEnabled={true}
        onClickReturnButton={() => navigate('/')}
      />
      <div className="mx-[300px] mt-10">
        <Typography
          variant="title"
          component="p"
          className="text-center mb-5 text-primary-light text-2xl"
        >
          Terms And Conditions
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          Podmínky použití Carian Healthcare
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          S účinností od 1.4. 2003 mají webové stránky Carian Healthcare
          aktualizované podmínky, které se vztahují na používání našich webových
          stránek (jak je zde definováno). Pro účely těchto Podmínek použití se
          termín „webové stránky“ souhrnně vztahuje na www.carian.healthcare a
          na lab.carian.healthcare.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          Webové stránky vám poskytujeme v souladu s těmito Podmínkami použití
          (dále jen „Podmínky“), které můžeme čas od času aktualizovat podle
          oddílu 1. těchto Podmínek. Vstupem na webové stránky a jejich
          používáním přijímáte a souhlasíte s tím, že budete vázáni těmito
          Podmínkami a Zásadami ochrany osobních údajů webových stránek Carian
          Healthcare, které najdete zde. Pokud s těmito Podmínkami nesouhlasíte,
          neměli byste přistupovat k webovým stránkám ani je používat. Kromě
          toho budete při přístupu na webové stránky podléhat všem zveřejněným
          pokynům nebo pravidlům platným pro webové stránky, které mohou být čas
          od času zveřejňovány a upravovány. Všechny tyto pokyny nebo pravidla
          jsou tímto začleněny formou odkazu do těchto podmínek.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          Tyto podmínky se nevztahují na váš přístup a používání produktů a
          služeb, které poskytujeme za předplatné. Postupy a zásady, včetně
          toho, jak chráníme, shromažďujeme a používáme elektronická data,
          snímky, texty, zprávy, komunikace nebo jiné materiály, které jste
          odeslali a uložili v rámci využívání webových stránek, jsou podrobně
          popsány v našich smluvních podmínkách a řídí se jimi.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          Tyto podmínky se nevztahují na váš přístup a používání produktů a
          služeb, které poskytujeme za předplatné. Postupy a zásady, včetně
          toho, jak chráníme, shromažďujeme a používáme elektronická data,
          snímky, texty, zprávy, komunikace nebo jiné materiály, které jste
          odeslali a uložili v rámci využívání webových stránek, jsou podrobně
          popsány v našich smluvních podmínkách a řídí se jimi.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          1. Změny podmínek.
          <br /> Tyto podmínky nebo jakoukoli jejich část můžeme upravit, včetně
          přidání nebo odstranění podmínek kdykoli, a takové úpravy, doplnění
          nebo odstranění vstoupí v platnost okamžitě po zveřejnění. Vaše
          používání webových stránek po takovém zveřejnění bude považováno za
          souhlas s takovými úpravami, přidáním nebo odstraněním.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          Na webových stránkách můžeme kdykoli změnit nebo ukončit jakýkoli
          aspekt, službu nebo funkci webových stránek, včetně, ale nikoli
          výhradně, obsahu, dostupnosti a vybavení potřebného pro přístup nebo
          použití.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          Na webových stránkách můžeme kdykoli změnit nebo ukončit jakýkoli
          aspekt, službu nebo funkci webových stránek, včetně, ale nikoli
          výhradně, obsahu, dostupnosti a vybavení potřebného pro přístup nebo
          použití.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          2. Registrace.
          <br />
          Můžete dostat příležitost zaregistrovat se prostřednictvím online
          registračního formuláře nebo účastí v interaktivních oblastech, jako
          jsou formuláře pro zpětnou vazbu, fóra a další funkce komunity, abyste
          si vytvořili uživatelský účet („Váš účet“), který vám umožní využívání
          našich Služeb („Služby“). Údaje, které nám poskytnete, použijeme v
          souladu se Zásadami ochrany osobních údajů. Registrací prohlašujete a
          zaručujete, že všechny informace, které uvedete v registračním
          formuláři, jsou aktuální, úplné a přesné podle vašeho nejlepšího
          vědomí. Souhlasíte s tím, že budete udržovat a neprodleně aktualizovat
          své registrační údaje na webových stránkách tak, aby zůstaly aktuální,
          úplné a přesné. Jste odpovědní za získání a udržování veškerého
          připojení, počítačového softwaru, hardwaru a dalšího vybavení
          potřebného pro přístup k webovým stránkám a jejich používání a za
          veškeré poplatky s tím související.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          3. Pokyny pro uživatelský obsah.
          <br />
          Na vámi odeslaný obsah se vztahují následující podmínky:
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          3.1 Webové stránky Carian healthcare mohou obsahovat formuláře pro
          zpětnou vazbu, sekce komentářů, nebo jiné interaktivní prvky
          („interaktivní oblasti“), do kterých můžete zveřejňovat nebo nahrávat
          svou zpětnou vazbu nebo jiný uživatelsky generovaný obsah, komentáře,
          snímky, zprávy a další materiály, nebo položky (souhrnně „Uživatelský
          obsah“). Jste výhradně odpovědní za své používání jakýchkoli
          interaktivních oblastí a používáte je na vlastní riziko. Interaktivní
          oblasti jsou k dispozici pro osoby starší 18 let. Odesláním
          uživatelského obsahu do interaktivní oblasti prohlašujete, že je vám
          18 let nebo více.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          3.2 Odesláním jakéhokoli uživatelského obsahu nebo účastí v
          interaktivní oblasti v rámci nebo v souvislosti s webovými stránkami
          Carian healthcare souhlasíte s tím, že nebudete nahrávat, zveřejňovat
          ani jinak přenášet žádný uživatelský obsah, který (a) porušuje nebo
          jakýmkoli způsobem porušuje práva ostatních , včetně jakýchkoli
          prohlášení, která mohou ohrožovat ostatní; (b) víte, že jsou
          nepravdivé, zavádějící nebo nepřesné; (c) obsahuje do očí bijící
          projevy fanatismu, rasismu, rasově nebo etnicky urážlivého obsahu,
          nenávistných projevů, urážek, vulgárnosti nebo nadávek; (d) obsahuje
          nebo propaguje pornografii nebo sexuálně explicitní obsah, pedofilii,
          incest, sodomii nebo jinak obscénní nebo oplzlý obsah; (e) porušuje
          jakýkoli zákon nebo obhajuje nebo poskytuje pokyny k nebezpečným,
          nezákonným nebo predátorským činům nebo diskutuje o nezákonných
          činnostech s úmyslem je spáchat; f) obhajuje násilné chování; g)
          představuje přiměřenou hrozbu pro osobní nebo veřejnou bezpečnost; (h)
          obsahuje násilné obrázky zabíjení nebo fyzického zneužívání, které se
          zdá být zachyceny výhradně nebo hlavně pro účely vykořisťování,
          chlípné nebo bezdůvodné; (i) je chráněna autorským právem, ochrannou
          známkou, obchodním tajemstvím, právem na zveřejnění nebo jiným
          vlastnickým právem bez výslovného svolení vlastníka těchto autorských
          práv, ochranné známky, obchodního tajemství, práva na zveřejnění nebo
          jiného vlastnického práva. Břemeno určení, že jakýkoli Uživatelský
          obsah není chráněn autorským právem, ochrannou známkou, obchodním
          tajemstvím, právem na publicitu nebo jiným vlastnickým právem, spočívá
          na vás. Jste výhradně odpovědní za jakoukoli škodu vyplývající z
          jakéhokoli porušení autorských práv, ochranných známek, obchodních
          tajemství, práv na publicitu nebo jiných vlastnických práv nebo za
          jakoukoli jinou škodu vyplývající z takového podání. Jakákoli osoba,
          kterou Carian Healthcare na základě vlastního uvážení určí, že
          porušila duševní vlastnictví nebo ostatní práva jiných osob, nebude
          moci odesílat nebo zveřejňovat jakýkoli další materiál na webových
          stránkách Carian healthcare; (j) obecně se netýká určeného tématu nebo
          tématu každé interaktivní oblasti; (k) obsahuje jakékoli nevyžádané
          nebo nepovolené reklamní nebo propagační materiály týkající se
          produktů nebo služeb.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          3.3 Souhlasíte s tím, že nebudete přímo ani nepřímo reprezentovat ani
          navrhovat podporu Uživatelského obsahu prostřednictvím webových
          stránek Carian Healthcare.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          3.4 Souhlasíte s tím, že nebudete nahrávat, zveřejňovat ani jinak
          přenášet žádný Uživatelský obsah, software nebo jiné materiály, které
          obsahují virus nebo jinou škodlivou nebo rušivou složku.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          3.5 Souhlasíte s tím, že nepoužijete žádnou technologii, službu nebo
          automatizovaný systém ke zveřejnění většího množství Uživatelského
          obsahu, než by jednotlivec mohl nahrát v daném časovém období.
          Souhlasíte také s tím, že nebudete nařizovat žádné třetí straně, aby
          tyto služby, technologie nebo automatizované systémy používala vaším
          jménem. 3.6 Jakékoli chování, o kterém se podle našeho výhradního
          uvážení domníváme, že omezuje nebo brání komukoli jinému v používání
          nebo užívání webových stránek, nebude povoleno. Vyhrazujeme si právo
          dle vlastního uvážení odstranit nebo upravit vámi zaslaný uživatelský
          obsah.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          3.7 Neneseme odpovědnost za přesnost nebo důvěryhodnost jakéhokoli
          Uživatelského obsahu a nepřebíráme žádnou odpovědnost ani nepřebíráme
          žádnou odpovědnost za jakékoli akce, které můžete podniknout v
          důsledku čtení Uživatelského obsahu zveřejněného na Webových
          stránkách. Při používání interaktivních oblastí můžete být vystaveni
          obsahu, který můžete považovat za urážlivý, nevhodný, škodlivý,
          nepřesný nebo klamavý. Může také existovat riziko jednání s
          nezletilými osobami, lidmi jednajícími pod falešnou záminkou, problémy
          mezinárodního obchodu a cizími státními příslušníky. Používáním
          interaktivních oblastí přebíráte všechna související rizika.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          3.8 Máme právo, nikoli však povinnost, monitorovat Uživatelský obsah
          zveřejněný nebo nahraný na Webové stránky Carian healthcare, abychom
          určili soulad s těmito Podmínkami a jakýmikoli provozními pravidly,
          která jsme stanovili, a abychom vyhověli jakémukoli zákonu, nařízení
          nebo oprávněnému vládnímu požadavku v daném státě. Přestože nejsme
          povinni monitorovat, prověřovat, upravovat nebo odstraňovat jakýkoli
          Uživatelský obsah zveřejněný nebo nahraný na Webové stránky,
          vyhrazujeme si právo a máme absolutní volnost bez upozornění
          prověřovat, upravovat, odmítat zveřejňovat nebo odstraňovat jakýkoli
          Uživatelský obsah, zveřejněný nebo nahraný na webové stránky kdykoli a
          z jakéhokoli důvodu a vy jste výhradně odpovědní za vytvoření
          záložních kopií a nahrazení jakéhokoli uživatelského obsahu
          zveřejněného na webových stránkách na své vlastní náklady a náklady.
          Rozhodnutí společnosti Carian Healthcare monitorovat a/nebo upravovat
          uživatelský obsah nebude považováno za žádnou odpovědnost nebo
          odpovědnost jakýmkoli způsobem z naší strany v souvislosti s vaším
          používáním interaktivních oblastí na webových stránkách nebo
          vyplývající z vašeho používání. Podmínky v článku 3.8 se nevztahují na
          váš přístup a používání produktů a služeb, které poskytujeme za
          předplatné. 3.9 Odesláním Uživatelského obsahu na Webové stránky nám
          automaticky udělujete bezplatné, trvalé, neodvolatelné, nevýhradní,
          celosvětové právo a licenci používat, publikovat, reprodukovat,
          upravovat, přizpůsobovat, překládat, vytvářet odvozená díla, začlenit
          do jiných děl, distribuovat, sublicencovat (prostřednictvím více
          úrovní) a jinak využívat takový Uživatelský obsah (zcela nebo zčásti)
          v jakékoli formě, médiích nebo technologiích, které jsou nyní známé
          nebo později vyvinuté, bez platby vám nebo jakýmkoli třetím stranám.
          Kromě toho se v plném rozsahu povoleném platnými zákony vzdáváte svých
          morálních práv k uživatelskému obsahu a souhlasíte s tím, že tato
          práva vůči nám nebudete uplatňovat. Prohlašujete a zaručujete nám, že
          máte plné zákonné právo, pravomoc a oprávnění udělit nám zde uvedenou
          licenci, že vlastníte všechna data a další práva k uživatelskému
          obsahu, který jste odeslali pro účely uvedené v těchto Podmínkách
          užití a že ani Uživatelský obsah, ani výkon zde udělených práv nesmí
          porušovat tyto Podmínky ani neporušovat žádná práva, včetně práva na
          soukromí nebo práva na publicitu, ani představovat urážku na cti nebo
          pomluvu proti nebo porušovat jakýkoli obecný zákon nebo jakékoli jiné
          právo nebo způsobit újmu jakékoli osobě nebo subjektu. Dále nám
          udělujete právo, nikoli však povinnost, právně stíhat jakoukoli osobu
          nebo subjekt, který poruší vaše nebo naše práva v uživatelském obsahu
          porušením těchto podmínek.
        </Typography>
        <Typography variant="title" component="p" className=" my-3 text-white ">
          4. Pokyny pro chování uživatele.
          <br /> Následující podmínky se vztahují na vaše chování při přístupu
          na webové stránky Carian healthcare nebo jejich používání: (a)
          souhlasíte s tím, že nebudete zasahovat do webových stránek nebo
          serverů nebo do sítí připojených k webovým stránkám; (b) souhlasíte s
          tím, že nebudete reprodukovat, duplikovat, kopírovat, dále prodávat a
          ani využívat pro žádné komerční účely jakoukoli část webových stránek
          Carian healthcare, používání webových stránek nebo přístup k těmto
          webovým stránkám; (c) souhlasíte s tím, že se nebudete podílet na
          žádné činnosti, která by představovala trestný čin nebo vedla k
          občanskoprávní odpovědnosti; (d) souhlasíte s tím, že se nebudete
          vydávat za žádnou osobu nebo subjekt, včetně, nikoli však výhradně,
          skupiny Carian s.r.o. nebo jakéhokoli zaměstnance skupiny Carian
          s.r.o., ani nebudete nepravdivě uvádět nebo jinak zkreslovat své
          spojení s jakoukoli osobou nebo subjektem; a (e) souhlasíte s tím, že
          nebudete zasahovat do práva jakéhokoli jiného uživatele na soukromí,
          včetně shromažďování nebo shromažďování osobně identifikovatelných
          informací o uživatelích webových stránek Carian healthcare nebo
          zveřejňování soukromých informací o třetí straně.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          5. Práva duševního vlastnictví.
          <br />
          Veškerý text, snímky a ostatní informace grafika, fotografie, ochranné
          známky, loga, ikony, uživatelská rozhraní, zvuky, hudba, videa,
          umělecká díla, software a počítačový kód (souhrnně „Obsah“), včetně,
          ale bez omezení na „vzhled a dojem“, rozvržení, design, struktura,
          barevné schéma, výběr, kombinace a uspořádání Obsahu přítomného na
          Webových stránkách Carian healthcare je naším vlastnictvím nebo máme
          na něj licenci. Takový obsah je chráněn autorským právem, ochrannou
          známkou, obchodním stylem a různými dalšími zákony o duševním
          vlastnictví a nekalé soutěži v daném státě.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          S výjimkou našeho výslovného písemného povolení nebo pokud to dovolují
          platné zákony, nesmíte kopírovat, distribuovat, reprodukovat,
          zrcadlit, označovat, veřejně zobrazovat, veřejně předvádět, překládat,
          vytvářet odvozená díla, znovu publikovat nebo přenášet webové stránky
          nebo obsah (v zcela nebo zčásti) jakýmkoli způsobem nebo
          prostřednictvím jakéhokoli média pro distribuci, publikaci nebo
          jakýkoli komerční účel.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          Obsah můžete zobrazovat, kopírovat a stahovat z webových stránek
          výhradně pro své osobní a nekomerční použití za předpokladu, že: (a) z
          Obsahu neodstraníte žádné upozornění na autorská práva nebo
          vlastnictví; (b) takový obsah nebude kopírován ani zveřejňován na
          žádném počítači v síti ani publikován na žádném médiu; a (c) v takovém
          obsahu nejsou prováděny žádné úpravy.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          6. Odmítnutí záruky a Omezení odpovědnosti.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          (A) VÝSLOVNĚ SOUHLASÍTE S TÍM, ŽE POUŽÍVÁNÍ WEBOVÝCH STRÁNEK CARIAN
          HEALTHCARE JE NA VAŠE VLASTNÍ RIZIKO. SKUPINA CARIAN S.R.O. ANI ŽÁDNÝ
          Z JEJICH PŘÍSLUŠNÝCH ZAMĚSTNANCŮ, ZÁSTUPCŮ, POSKYTOVATELŮ OBSAHU
          TŘETÍCH STRAN, POSKYTOVATELŮ SLUŽEB TŘETÍCH STRAN NEBO POSKYTOVATELŮ
          LICENCE NEZARUČUJÍ, ŽE POUŽÍVÁNÍ STRÁNEK BUDE BEZPLATNÉ; ANI
          NEPOSKYTUJÍ ŽÁDNOU ZÁRUKU TÝKAJÍCÍ SE VÝSLEDKŮ, KTERÉ LZE ZÍSKAT
          POUŽÍVÁNÍM WEBU, ANI ZA PŘESNOST, SPOLEHLIVOST NEBO OBSAH JAKÝCHKOLI
          INFORMACÍ, SLUŽEB NEBO ZBOŽÍ POSKYTOVANÝCH PROSTŘEDNICTVÍM STRÁNEK.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          (B) WEBOVÉ STRÁNKY CARINA HEALTHCARE JSOU POSKYTOVÁNY NA ZÁKLADĚ „TAK,
          JAK JSOU“, BEZ ZÁRUK JAKÉHOKOLI DRUHU, VÝSLOVNÝCH NEBO
          PŘEDPOKLÁDANÝCH, VČETNĚ, ALE NE OMEZENÝCH ZÁRUK VLASTNICTVÍ NEBO
          PŘEDPOKLÁDANÝCH ZÁRUK OBCHODOVATELNOSTI JINÝCH PARTNERŮ NEBO ZÁRUKY
          PARTNERŮ, KTERÉ JSOU IMPLIKOVANÉ A JE NEMOŽNÉ JE VYLOUČIT, OMEZIT NEBO
          ZMĚNIT PODLE PLATNÝCH ZÁKONŮ A TĚCHTO PODMÍNEK.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          (C) V NEJVYŠŠÍM ROZSAHU POVOLENÉM PLATNÝM ZÁKONEM VÁM V ŽÁDNÉM PŘÍPADĚ
          NEBUDE SKUPINA CARIAN S.R.O., ANI JEJÍ BUDOUCÍ MATEŘSKÉ NEBO
          PŘIDRUŽENÉ SPOLEČNOSTI ODPOVĚDNÉ ZA JAKÉKOLI ÚRAZY OSOB, ŠKODY NA
          MAJETKU, ZTRÁTY ZISKU, ODPOVĚDNOSTI ZA ZTRÁTU DAT, ZTRÁTU DOBRÉHO
          JMÉNA, ZASTAVENÍ PRÁCE, SELHÁNÍ TECHNOLOGIE POČÍTAČE A/NEBO JAKÁKOLIV
          FORMA PŘÍMÉ NEBO NEPŘÍMÉ, ZVLÁŠTNÍ, NÁHODNÉ, NÁSLEDNÉ, VZOROVÉ FORMY
          NEBO ZA UPLATNĚNÍ TRESTU Z WEBOVÝCH STRÁNEK CARIAN HEALTHCARE NEBO
          JAKÉHOKOLI ÚDAJNÉHO FUNGOVÁNÍ, CHYBY, VYDÁNÍ VERZE, PŘERUŠENÍ,
          VYMAZÁNÍ, ZÁVADY NEBO ZPOŽDĚNÍ SLUŽBY, PROVOZU NEBO PŘENOSU Z WEBOVÝCH
          STRÁNEK NEBO JAKÉHOKOLI ÚDAJNÉHO POČÍTAČOVÉHO VIRU, KOMUNIKACE,
          POŠKOZENÍ NEBO NEOPRÁVNĚNÝ PŘÍSTUP K JAKÝMKOLIV ZÁZNAMŮM OBSAHU NEBO
          TECHNOLOGII TÝKAJÍCÍ SE WEBOVÝCH STRÁNEK CARIAN HEALTHCARE. POUŽÍVÁNÍ
          NEBO POSKYTOVÁNÍ JAKÝCHKOLIV ZÁZNAMŮ OBSAHU NEBO TECHNOLOGIÍ NA
          WEBOVÝCH STRÁNKÁCH CARIAN HEALTHCARE. SOUHLASÍTE S TÍM, ŽE TOTO
          OMEZENÍ ODPOVĚDNOSTI PLATÍ, AŤ JSOU TAKOVÁ TVRZENÍ ZA PORUŠENÍ
          SMLOUVY, JAKO JSOU, TRAKČNÍ CHOVÁNÍ, NEDBALOST NEBO AKCE NÁSLEDOVÁNÍ Z
          JAKÝCHKOLIV JINÝCH PŘÍČIN, BEZ OHLEDU NA ZÁKLAD, A TO BEZ OMEZENÍ
          VŠEOBECNOSTI VÝŠE UVEDENÝCH. TAKÉ SPECIÁLNĚ POTVRZUJETE, ŽE SKUPINA
          CARIAN S.R.O. ANI BUDOUCÍ MATEŘSKÉ NEBO PŘIDRUŽENÉ SPOLEČNOSTI
          NEODPOVÍDAJÍ ZA JAKÉKOLI SKUTEČNÉ, POVINNÉ NEBO URÁŽLIVÉ ÚDAJE.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          POKUD PLATNÝ ZÁKON NEUMOŽŇUJE, ABY SE NA VÁS VZTAHOVALO VŠE NEBO
          JAKÁKOLIV Z ČÁSTÍ VÝŠE UVEDENÝCH OMEZENÍ ODPOVĚDNOSTI, OMEZENÍ SE NA
          VÁS VZTAHUJE POUZE V ROZSAHU POVOLENÉM PŘÍSLUŠNÝM ZÁKONEM V DANÉM
          STÁTĚ.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          (D) Zříkáme se jakékoli odpovědnosti jakéhokoli druhu za jakýkoli
          neoprávněný přístup k vašim osobním údajům nebo jejich použití.
          Vstupem na webové stránky Carian healthcare berete na vědomí a
          souhlasíte s naším odmítnutím jakékoli takové odpovědnosti. Pokud
          nesouhlasíte, neměli byste přistupovat na webové stránky ani je
          používat.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          7. Ukončení.
          <br />
          Tyto Podmínky můžeme kdykoli ukončit nebo pozastavit bez předchozího
          upozornění. Aniž bychom omezovali výše uvedené, máme právo okamžitě
          ukončit váš přístup na webové stránky v případě jakéhokoli vašeho
          chování, které podle vlastního uvážení považujeme za nepřijatelné,
          nebo v případě jakéhokoli porušení těchto zásad z vaší strany.
          Podmínky. Ustanovení odstavců 2 a 4-11 zůstanou v platnosti i po
          ukončení těchto podmínek
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          8. Rozhodné právo. <br />
          Obsah, data, video a veškerý další materiál a funkce na webových
          stránkách jsou prezentovány za účelem poskytování dat, zpráv a/nebo
          informací a dalších produktů a/nebo služeb, které jsou nebo mohou být
          dostupné na území Evropské Unie.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          Jakékoli a všechny spory, nároky a kontroverze vzniklé z nebo v
          souvislosti s vaším přístupem na webové stránky a/nebo jejich
          používáním a/nebo poskytováním obsahu, služeb a/nebo technologií na
          webových stránkách nebo jejich prostřednictvím se řídí a vykládány
          výhradně v souladu se zákony a rozhodnutími daného státu, které se
          vztahují na smlouvy uzavřené, uzavřené a plně v nich plněné, aniž by
          byla uvedena v platnost ustanovení o kolizi zákonů.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          Oznamte prosím jakékoli porušení těchto podmínek právnímu oddělení
          Carian s.r.o.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          9. Autorská práva a zástupce pro autorská práva. Respektujeme práva
          duševního vlastnictví ostatních a očekáváme, že naši uživatelé a
          zákazníci budou dělat totéž. Vyhrazujeme si právo ukončit přístup na
          webové stránky Carian healthcare uživatelům nebo zákazníkům, kteří
          zveřejňují materiál porušující práva duševního vlastnictví jiných
          osob.
        </Typography>

        <Typography variant="title" component="p" className=" my-3 text-white ">
          10. Různé.
          <br />
          Tyto podmínky a veškerá provozní pravidla pro námi zřízené webové
          stránky Carian healthcare představují úplnou dohodu stran s ohledem na
          předmět této smlouvy a nahrazují všechny předchozí písemné nebo ústní
          dohody mezi stranami s ohledem na takový předmět. Ustanovení těchto
          Podmínek jsou ve prospěch skupiny Carian s.r.o., jejích přidružených
          společností a jejích třetích stran, poskytovatelů obsahu a
          poskytovatelů licencí, a každý z nich má právo prosazovat a vymáhat
          taková ustanovení přímo nebo svým vlastním jménem. Žádné zřeknutí se
          jakéhokoli porušení nebo porušení této smlouvy kteroukoli stranou
          nebude považováno za zřeknutí se jakéhokoli předchozího nebo
          následného porušení dohod nebo škod. Pokud soud příslušné jurisdikce
          shledá jakoukoli část těchto podmínek neplatnou nebo nevymahatelnou,
          bude nahrazena zněním odrážejícím původní účel platným a vymahatelným
          způsobem. Vymahatelné části těchto podmínek zůstanou pro strany
          závazné. Názvy oddílů použité v tomto dokumentu slouží pouze pro
          pohodlí a nemají žádný právní význam.{' '}
        </Typography>
      </div>
    </div>
  );
};

export default TermsAndConditions;
