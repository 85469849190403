import React, { ReactNode } from 'react';
import './RectangleButton.css';
import { Icon } from '@ohif/ui';

interface Props {
  number?: number;
  onClick: () => void;
  text: string;
  numberColor?: string;
  iconName?: string;
}

const RectangleButton: React.FC<Props> = ({
  onClick,
  number,
  text,
  numberColor,
  iconName,
}) => {
  return (
    <div
      className="cursor-pointer border-white border-2 w-[12.5em] h-[100px] rounded-[10px] flex justify-around flex-row p-[0.9em] items-center"
      onClick={onClick}
    >
      {!number ? (
        <div className="flex justify-center text-center items-center">
          {iconName && (
            <div>
              <Icon
                name={iconName}
                style={{ color: '#000', width: '25px', height: '20px' }}
              />
            </div>
          )}
          <span className="rectangleButtonBox-text rectangleButtonBox-onlyText">
            {' '}
            {text}
          </span>
        </div>
      ) : (
        <>
          <div className="rectangleButtonBox-textBox">
            <span
              className="rectangleButtonBox-number "
              style={{ color: numberColor || 'white' }}
            >
              {number}
            </span>
          </div>
          <div className="rectangleButtonBox-textBox">
            {iconName && (
              <div>
                <Icon
                  name={iconName}
                  style={{ color: '#000', width: '20px', height: '20px' }}
                />
              </div>
            )}
            <span className="rectangleButtonBox-text">{text}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default RectangleButton;
