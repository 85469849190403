import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { getGeneralApiProblem } from './api-problem';

export class StudiesApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async search(filters: any): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        '/studies/study',
        filters
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async assignStudy(id: string, physicianId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.put(
        `/studies/study/${id}`,
        physicianId
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getStudy(id: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/${id}`
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getStudySeries(id: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/${id}/series`
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
  async getNewCount(id: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/count?status=New`
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getCount(id: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/count`
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getCountNewStudies(id: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/count`,
        { status: ['New'] }
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getCountList(params: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/count`,
        params
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getDraftCount(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/count`,
        { status: ['Open'] }
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMissingPhysicianCount(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/count`,
        { missingPhysician: 'true' }
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getErrorCount(): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/count?missingPhysician=true`
      );
      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async createOTA(studyId: string): Promise<any> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        `/studies/study/${studyId}/ota`
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return response.data;
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
