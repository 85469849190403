import React from 'react';
import { Link } from 'react-router-dom';
import {
  RectangleButton,
  DashBoardTitleBox,
  RecommendCarianModal,
} from '@ohif/ui';

interface Props {
  userName: string;
  api: any;
  checked: boolean;
}

const PhysicianDashboard: React.FC<Props> = ({
  userName,
  checked,
  api,
  userAuthenticationService,
  servicesManager,
  modalService,
}) => {
  return (
    <div
      className={`flex items-center ${checked ? 'justify-start' : 'justify-center'
        } align-middle content-center	self-center	justify-self-center	flex-1 mt-[6em] mx-2`}
    >
      <div className="grid justify-items-center">
        <div className="mb-[5em]">
          <DashBoardTitleBox
            title={`Welcome ${userName}`}
            subTitle={'You are a requesting physician'}
          />
        </div>
        <div className="grid justify-items-stretch grid-cols-3 justify-items-stretch  gap-x-[60px] gap-y-[50px]  w-[45.8em] max-w-[900px] mb-[4em]">
          <Link to={`/studies`}>
            <RectangleButton text={`Your studies`} />
          </Link>
          <Link to={`/diagnosisDictionary`}>
            <RectangleButton text={'Edit your diagnosis dictionary'} />
          </Link>
          <RectangleButton
            onClick={() =>
              modalService.show({
                layout: checked,
                content: RecommendCarianModal,
                customClassName: `border-2 border-white bg-primary-dark rounded-2xl w-[450px] h-[320px] m-auto`,
                contentProps: {
                  servicesManager,
                  onClose: () => {
                    modalService.hide();
                  },
                },
              })
            }
            text={'Recommend Carian to a colleague'}
          />
          {/* <RectangleButton text={'Participate improve Carian'} /> */}
        </div>
      </div>
    </div>
  );
};

export default PhysicianDashboard;
