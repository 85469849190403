import React from 'react';
import { Icon, Typography, Button } from '@ohif/ui';

interface Props {
  title: string;
  description: string;
  show: boolean;
  setOpenInformation: any;
}

const DiagnosisInformation: React.FC<Props> = ({
  title,
  description,
  show,
  setOpenInformation,
}) => {
  return (
    <>
      {show && (
        <div className="z-10 w-96 h-[500px] absolute inset-0 m-auto text-white bg-primary-dark self-center border-4 rounded-lg border-common-active">
          <header className="bg-common-active flex justify-between p-4 border-2 border-common-active">
            <abbr title={title} className="truncate w-full no-underline">
              <Typography variant="h6" className="truncate w-full">
                {title}
              </Typography>
            </abbr>
            <button
              className="text-white w-6 h-6"
              onClick={() => {
                setOpenInformation({
                  textDiagnosis: null,
                  descriptionDiagnosis: null,
                  show: false,
                });
              }}
            >
              <Icon className="text-black" name="close" />
            </button>
          </header>
          <div className="px-5 mt-10">
            <div className="my-5">
              <label htmlFor="">Diagnosis</label>
              <input
                type="text"
                value={title}
                className="w-full mt-2 px-2 rounded-md bg-black border border-white"
              />
            </div>
            <div className="my-5">
              <label htmlFor="">Description</label>
              <textarea
                value={description}
                className="h-48 mt-2 px-2 w-full rounded-md bg-black border border-white"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DiagnosisInformation;
