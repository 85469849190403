import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Draggable from 'react-draggable';
import Icon from '../Icon';
import {
  HandleEdit,
  EditDictionaryModal,
  HandleDelete,
  HandleSelect,
  NewDiagnosisModal,
  EditDiagnosisModal,
} from '@ohif/ui';
import moment from 'moment';
import { useImageViewer } from '../../contextProviders';

interface Props {
  diagnosisIndex: number;
  label: string;
  user: any;
  selected: number;
  setSelected: (index: number) => void | any;
  onSelected?: (index: number) => void | any;
  createdAt: string;
  studyId: string;
  madeBy: string;
  description: string;
  diagnosis: any;
  api: any;
  exportsDiagnosis: boolean;
  setExportContent?: any;
  exportContentState?: any;
  id: string;
  patientName: string;
  patientId: string;
  patientBirthDate: string;
  studyDescription: string;
  studyDate: string;
  institutionName: string;
  contact: string;
  referringPhysician: string;
  IDNo: string;
  labName: string;
  dictionaryApi: any;
}

const DiagnosisItem: React.FC<Props> = ({
  api,
  exportsDiagnosis,
  diagnosisIndex,
  label,
  diagnosis,
  selected,
  setSelected,
  onSelected,
  description,
  studyId,
  madeBy,
  createdAt,
  setExportContent,
  exportContentState,
  id,
  patientName,
  patientId,
  patientBirthDate,
  studyDescription,
  studyDate,
  institutionName,
  contact,
  referringPhysician,
  IDNo,
  labName,
  dictionaryApi,
  user,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = () => setIsHovering(true);
  const onMouseLeave = () => setIsHovering(false);
  const isActive = diagnosisIndex === selected ? true : false;
  const [show, setShow] = useState('hidden');
  const [descriptionState, setDescriptionState] = useState(description);
  const [itemState, setItemState] = useState({
    id: '',
    title: '',
    description: '',
    show: false,
  });

  const startTime = diagnosis.updatedAt
    ? moment(diagnosis.updatedAt)
    : moment(diagnosis.createddAt);
  const now = moment();
  const diff = now.diff(startTime, 'hours');
  const canEdit = diff < 3;
  const [showEditDiagnosis, setShowEditDiagnosis] = useState(false);
  const [diagnosisList, setDiagnosisList] = useState([]);
  const physicianFullName = `${diagnosis.physician?.user?.lastName} ${diagnosis.physician?.user?.firstName}`;
  const handleClick = () => {
    setShowEditDiagnosis(!showEditDiagnosis);
    setItemState({
      id: '',
      title: label,
      description: description,
      show: true,
    });
    if (onSelected) {
      onSelected(diagnosisIndex);
    } else {
      setSelected(diagnosisIndex);

      if (show === 'hidden') {
        setShow('block');
      } else {
        setShow('hidden');
      }
    }
  };
  const { StudyInternalUIDs } = useImageViewer();

  const getData = async () => {
    const result = await api.diagnosis.list(StudyInternalUIDs);
    setDiagnosisList(result);
  };

  useEffect(() => {
    getData();
  }, [isActive]);
  return (
    <>
      <div
        className={' mb-[2px]  z-0 flex w-full'}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={classnames(
            'w-full group relative flex cursor-pointer bg-primary-dark transition duration-300 text-[12px] overflow-hidden border border-black rounded-[3px]',
            { ' border-primary-light ': isActive }
          )}
          role="button"
          data-cy="segment-item"
          onClick={() => diagnosisList[0]?.status !== 'Draft' && handleClick()}
        >
          <div
            className={classnames(
              '  flex items-center justify-center border-r border-r-black text-[12px]   rounded-l-sm',
              { ' bg-secondary-main  text-black': isHovering && !isActive },
              { 'text-primary-light': !isHovering && !isActive },
              { 'bg-primary-light text-black': isActive }
            )}
          >
            <div className="flex items-center pr-2 pl-1 ">
              {diagnosisIndex + 1}
            </div>
          </div>

          <div className="flex items-center justify-between w-full pl-2 py-1 text-white border-r border-r-black relative  bg-primary-dark text-primary-light">
            <div className="flex items-baseline gap-2">
              <div className="shrink-0 w-[8px] h-[8px] rounded-full"></div>
              <div className="">{`${label} ${diagnosis.status === 'Draft' ? 'Draft' : ''
                }`}</div>
              <div className="">{physicianFullName}</div>
            </div>

            {/* <div className="absolute right-0 bg-black/15 rounded-lg pr-[7px]"></div> */}
          </div>
        </div>
        {exportsDiagnosis ? (
          <HandleSelect
            id={id}
            title={label}
            description={description}
            patientBirthDate={patientBirthDate}
            createdAt={createdAt}
            studyId={studyId}
            madeBy={madeBy}
            patientId={patientId}
            patientName={patientName}
            setExportContent={setExportContent}
            exportContentState={exportContentState}
            studyDescription={studyDescription}
            studyDate={studyDate}
            institutionName={institutionName}
            contact={contact}
            referringPhysician={referringPhysician}
            IDNo={IDNo}
            labName={labName}
          />
        ) : (
          <>
            <div className="bg-primary-light flex items-center">
              {diagnosisList[0]?.physician?.id === user?.roleEntity?.id &&
                diagnosisList[0]?.status !== 'Draft' ? (
                <button
                  className="text-white h-4 flex items-center text-xl pr-2 text-secondary-dark"
                  onClick={() => {
                    setShowEditDiagnosis(!showEditDiagnosis);
                    setItemState({
                      id: '',
                      title: label,
                      description: description,
                      show: true,
                    });
                  }}
                >
                  <Icon className="text-white w-4 ml-2" name="pencil" />
                </button>
              ) : null}
              {showEditDiagnosis && (
                <EditDiagnosisModal
                  diagnosisStatus={diagnosisList[0]?.study?.status}
                  api={api}
                  userRole={user?.user?.roleId}
                  user={user?.roleEntity?.id}
                  studyPhysicianId={diagnosisList[0]?.physician?.id}
                  dictionaryApi={dictionaryApi}
                  show={showEditDiagnosis}
                  setShow={display => setShowEditDiagnosis(display)}
                  modalTitle={itemState.title}
                  handleDidSave={() => {
                    getData();
                    setShowEditDiagnosis(false);
                  }}
                  descriptionState={descriptionState}
                  setDescriptionState={setDescriptionState}
                  handleSavedDraft={() => {
                    getData();
                  }}
                  id={itemState.id}
                  description={itemState.description}
                  onEdit={() => setItemState({ ...itemState, show: false })}
                />
              )}
            </div>
            {/* <div className="bg-primary-light flex items-center">
              {canEdit && <HandleDelete id={'1'} />}
            </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default DiagnosisItem;
